export const PermissionType = {
    lab:["Settings","Patient","cliche","Tutorial"],
    note:["Settings","Note","Tutorial"],
    application:["Application","Settings","Tutorial"],
    admin:["Settings","Report","Note","LabPage","Application","HR","Patient","Firebase","cliche","Tutorial"],
    Secretary:["Settings","Patient","Tutorial","LabPage"],

}
   

