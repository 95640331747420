import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { API_BASE_URL, HTTP_STATUS } from "../config/serverApiConfig";

export const addInvoiceR = createAsyncThunk("invoice/add", async (data) => {
    const {client,matter, ...all} = data
    console.log(all,"in Redux")
  const response = await axios
    .post(`${API_BASE_URL}invoice/new?hr_name=${data.name}`, data.data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const getInvoice = createAsyncThunk("invoice/get", async (data) => {
  const response = await axios
    .get(`${API_BASE_URL}matter/invoice?current=${data.current}&limit=${data.limit}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});


export const getInvoiceSoloR = createAsyncThunk("invoice/getSolo", async (id) => {
  console.log(id)
    const response = await axios
      .get(`${API_BASE_URL}invoice?matter_id=${id}`)
      .then((response) => {
        console.log(response.data,"hello")
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  
    return response;
  });

export const delInvoice = createAsyncThunk("invoice/del", async (id) => {
  const response = await axios
    .delete(`${API_BASE_URL}invoice/delete?id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return id;
});

export const updateInvoice = createAsyncThunk("invoice/update", async (fd) => {
 
  const response = await axios
    .put(`${API_BASE_URL}invoice/update?id=${fd.id}`,fd.fd)
    .then((response) => {
      
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return {data:response,id:fd.id};
});

const InvoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    

    pagination: {
      page: 1,
      pageSize: 15,
      total: 10,
    },
    status: null,
  },
  reducers: {
    NotePagination: (state, action) => {
      state.pagination ={
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        total: action.payload.total,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addInvoiceR.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(addInvoiceR.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.invoice = [...state.invoice, action.payload];
      })
      .addCase(addInvoiceR.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    //   builder
    //   .addCase(addInvoice.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(addInvoice.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.invoice = [...state.client, action.payload];
    //   })
    //   .addCase(addInvoice.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
    builder
      .addCase(getInvoice.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.invoice = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(getInvoice.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(delInvoice.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(delInvoice.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.invoice = state.invoice.filter((item) => item.id !== action.payload);
      })
      .addCase(delInvoice.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(updateInvoice.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.invoice = state.invoice.map((item) => item.id === action.payload.id ? action.payload.data:item);

      })
      .addCase(updateInvoice.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });

      builder
      .addCase(getInvoiceSoloR.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getInvoiceSoloR.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.soloInvoice = action.payload;
      
      })
      .addCase(getInvoiceSoloR.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
  },
});

export const { NotePagination } = InvoiceSlice.actions;

export default InvoiceSlice.reducer;
