import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Image, Modal, Popconfirm, Row, Select, Typography, Upload, message, theme } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/serverApiConfig";
import { PhotoProvider,PhotoView } from "react-photo-view";
import { nanoid } from "nanoid";
import 'react-photo-view/dist/react-photo-view.css';

const { Title, Paragraph } = Typography;

function BeforeAfter() {
  const [visible, setVisible] = useState(false);
  const { useToken } = theme;
  const { token } = useToken();
  const { id } = useParams();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState({
    before: [],
    after: [],
  });
  const [rec, setRec] = useState({});
  const [NoteForm] = Form.useForm();

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    axios.get(`${API_BASE_URL}patient/comparsion/${id}`).then((res) => {
      setData({
        before: res.data.before,
        after: res.data.after,
      });
    });
  }, []);


  const submitWork = (value) => {
    setConfirmLoading(true);
    let formData = new FormData();

    if (value.upload !== undefined && value.upload.fileList.length !== 0) {
      for (let i = 0; i < value.upload.fileList.length; i++) {
        formData.append("image", value.upload.fileList[i].originFileObj);
      }
    }


    axios
      .post(`${API_BASE_URL}patient/comparsion?p_id=${id}&bar=${value.bar}`, formData)
      .then((res) => {
        setVisible(false);
        setConfirmLoading(false);
        message.success("وێنەکە زیادکرا");

      
          setData({ ...data, [value.bar]: data[value.bar].concat(res.data[value.bar]) });

      })
      .catch((err) => {
        setConfirmLoading(false);

        message.error("هەڵەیەک ڕویدا لە زیادکردنی وێنە!");
      });
  };

  const handleDelete = (rec, bar) => {
    
    axios
      .delete(`${API_BASE_URL}patient/comparsion/${rec.id}?bar=${bar}`)
      .then((res) => {
        setData({ ...data, [bar]: data[bar].filter((item) => item.id !== rec.id) });
      })
      .catch((err) => {
        message.error("هەڵەیەک هەیە لە سڕینەوەی وێنە");
      });
  };


  const handleDragStart = (e) => {
    console.log(e);
  };

  return (
    <div>
      <Row justify="space-around" style={{ marginTop: 0, borderRadius: 6, padding: 10, backgroundColor: token.colorBgContainer }}>
        <Col xs={24}>
          <Row justify={"space-between"}>
            <Title level={4}>وێنەکان</Title>
            <Button onClick={() => setVisible(true)} type="primary">
              زیادکردن
            </Button>
          </Row>
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={23} sm={23} md={23} lg={16} xl={16} xxl={16} style={{ backgroundColor: token.colorBgContainer, padding: token.padding, marginTop: 40, borderRadius: token.borderRadius }}>
         <PhotoProvider  maskOpacity={0.5}>

        
          <Row justify={"space-around"}>
            <Title style={{ margin: "auto" }} level={4}>
              پێشتر
            </Title>
            <Title style={{ margin: "auto" }} level={4}>
              دواتر
            </Title>

            <Divider type="horizontal" />

            <div style={{ position: "absolute", left: "50%", bottom: 0, width: 1, height: "100%", backgroundColor: "#f3f3f3" }}></div>

            <Col>
              <div style={{ display: "flex", flexDirection: "column" }} className="grident_safeBox">
            


                {data.before.map((i) => {
                  return (
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start'}}>

<PhotoView key={nanoid(2)}  src={i.befor}>
                      <img  width={150} height={150} src={i.befor}

                 
                      
                    

                      style={{ marginBlock: 7, objectFit: "contain",cursor:"pointer" }} />

</PhotoView>
                      <Popconfirm
                       title="ئایا دڵنیایت لە سڕینەوەی وێنە؟" okText="بەڵێ" cancelText="نەخێر"
                       onConfirm={() => handleDelete(i, "before")}
                      
                      >
 <Button type="link" danger><DeleteOutlined /></Button>
                      </Popconfirm>
                     
                      </div>
                  );
                })}
                
              </div>
            </Col>
            <Col>
              <div style={{ display: "flex", flexDirection: "column" }} className="grident_safeBox">
                {data.after.map((i) => {
                  return (
                    <>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start'}}>

                    <PhotoView  key={nanoid(2)}  src={i.after}>

                      <img  width={150} height={150} 
                      
               

                      src={i.after} style={{ marginBlock: 7, objectFit: "contain",cursor:"pointer" }} />

                      </PhotoView>
                      <Popconfirm
                       title="ئایا دڵنیایت لە سڕینەوەی وێنە؟" okText="بەڵێ" cancelText="نەخێر"
                       onConfirm={() => handleDelete(i, "after")}
                      
                      >
                      <Button type="link" danger><DeleteOutlined /></Button>
                      </Popconfirm>
                      </div>

                    </>
                  );
                })}
              </div>
            </Col>
          </Row>

          </PhotoProvider>

        </Col>
      </Row>

      <Modal
        onOk={() => NoteForm.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setUpdate(false);
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی وێنە
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitWork(values);
            }
          }}
        >
          <Form name="AWS" form={NoteForm} layout="horizontal">
            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"بار"} labelCol={{ span: 5 }} name={"bar"}>
              <Select
                options={[
                  { label: "پێشتر", value: "before" },
                  { label: "دواتر", value: "after" },
                ]}
                placeholder="هەڵبژاردنی بار"
              />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"وێنە"} labelCol={{ span: 5 }} name={"upload"}>
              <Upload listType="picture-card" multiple accept="image/*" customRequest={dummyRequest}>
                <svg width="129" height="80" viewBox="0 0 129 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd" d="M25.8909 14.8648C27.2169 10.6728 28.4509 8.06277 29.5909 7.03377C32.7479 4.18577 37.1129 5.73577 38.0409 5.95777C41.3009 6.73977 40.2409 1.59377 43.0379 0.547773C44.9019 -0.149227 46.4349 0.702773 47.6379 3.10377C48.7029 0.863773 50.3259 -0.162227 52.5069 0.0207726C55.7789 0.297773 56.9239 11.3488 61.4199 8.92977C65.9169 6.50977 71.4299 5.95677 73.7849 9.55277C74.2939 10.3308 74.4889 9.12377 77.9509 5.00277C81.4129 0.880772 84.8649 -0.935228 91.9469 1.46477C95.1669 2.55477 97.8149 5.50977 99.8939 10.3288C99.8939 17.2068 104.954 21.2788 115.072 22.5418C130.251 24.4368 118.469 40.7558 99.8939 45.5348C81.3179 50.3148 38.5509 52.8948 15.3429 40.8188C-0.129066 32.7698 3.38693 24.1178 25.8899 14.8648H25.8909Z" fill="url(#paint0_linear_184_313)" />
                  <path d="M63.9509 75.6447C79.4077 75.6447 91.9379 72.7444 91.9379 69.1667C91.9379 65.589 79.4077 62.6887 63.9509 62.6887C48.4941 62.6887 35.9639 65.589 35.9639 69.1667C35.9639 72.7444 48.4941 75.6447 63.9509 75.6447Z" fill="url(#paint1_linear_184_313)" />
                  <path opacity="0.675" fill-rule="evenodd" clip-rule="evenodd" d="M111.201 77.2498C90.1579 82.5278 18.3309 76.4908 10.6859 73.7338C6.9649 72.3908 3.6109 69.8658 0.624903 66.1578C0.290703 65.7432 0.0806373 65.2425 0.0189802 64.7136C-0.0426768 64.1846 0.0465916 63.649 0.27647 63.1686C0.506348 62.6883 0.867453 62.2828 1.31806 61.999C1.76866 61.7152 2.29037 61.5646 2.8229 61.5648H128.337C130.942 68.5028 125.23 73.7308 111.201 77.2498Z" fill="url(#paint2_linear_184_313)" />
                  <path d="M85.4269 51.8058L75.8559 40.6138C75.6295 40.3468 75.3483 40.1317 75.0312 39.9833C74.7142 39.8349 74.3689 39.7566 74.0189 39.7538H53.4389C52.7329 39.7538 52.0619 40.0778 51.6019 40.6138L42.0309 51.8058V57.9498H85.4269V51.8058Z" fill="url(#paint3_linear_184_313)" />
                  <path d="M82.7149 58.1758L74.3379 48.4278C74.1354 48.1977 73.8857 48.0138 73.6058 47.8887C73.3259 47.7636 73.0224 47.7002 72.7159 47.7028H54.7419C54.1249 47.7028 53.5219 47.9588 53.1199 48.4278L44.7429 58.1758V63.5298H82.7149V58.1758Z" fill="url(#paint4_linear_184_313)" />
                  <path d="M85.4269 64.9908C85.4269 65.8438 85.0429 66.6108 84.4369 67.1248L84.3139 67.2248C83.8334 67.589 83.2468 67.7857 82.6439 67.7848H44.8149C44.4729 67.7848 44.1459 67.7228 43.8439 67.6088L43.6939 67.5488C43.199 67.3286 42.7786 66.9697 42.4834 66.5156C42.1883 66.0614 42.0311 65.5314 42.0309 64.9898V51.8728H52.5599C53.7229 51.8728 54.6599 52.8298 54.6599 53.9908V54.0058C54.6599 55.1678 55.6079 56.1048 56.7709 56.1048H70.6869C71.2459 56.1043 71.7819 55.8822 72.1776 55.4873C72.5733 55.0924 72.7963 54.5568 72.7979 53.9978C72.7979 52.8318 73.7359 51.8728 74.8979 51.8728H85.4279L85.4269 64.9908Z" fill="url(#paint5_linear_184_313)" />
                  <defs>
                    <linearGradient id="paint0_linear_184_313" x1="6085.3" y1="3695" x2="6085.3" y2="-873.348" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#DEDEDE" stop-opacity="0" />
                      <stop offset="1" stop-color="#A9A9A9" stop-opacity="0.3" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_184_313" x1="2543.04" y1="1358.29" x2="2543.04" y2="62.6887" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white" stop-opacity="0" />
                      <stop offset="1" stop-color="#96A1C5" stop-opacity="0.373" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_184_313" x1="6447.44" y1="1839.4" x2="6447.44" y2="-288.225" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white" stop-opacity="0" />
                      <stop offset="1" stop-color="#919191" stop-opacity="0.15" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_184_313" x1="2211.83" y1="39.7538" x2="2211.83" y2="857.664" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#5389F5" />
                      <stop offset="1" stop-color="#416FDC" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_184_313" x1="2450.08" y1="1630.41" x2="2450.08" y2="-36.434" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#DCE9FF" />
                      <stop offset="1" stop-color="#B6CFFF" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_184_313" x1="2211.88" y1="51.8728" x2="2211.88" y2="1643.07" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#7CA5F7" />
                      <stop offset="1" stop-color="#C4D6FC" />
                    </linearGradient>
                  </defs>
                </svg>
              </Upload>
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
}

export default BeforeAfter;
