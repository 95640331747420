import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";


import React from "react";
import { PermissionType } from "./PermissionType";
import { useSelector } from "react-redux";

const AuthType = ({ allowedRoles }) => {
  
    const authSelector = useSelector((state) => state.auth.auth);

  return allowedRoles.includes(authSelector.role) ? (
    <Outlet />
  ) : authSelector.id ? (
    <Navigate to="/"  replace />
  ) : (
    <Navigate to="/login"  replace />
  );
};

export default AuthType;