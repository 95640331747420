import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { API_BASE_URL, HTTP_STATUS, PAGESIZEOPTION } from "../config/serverApiConfig";

// export const addClient = createAsyncThunk("ag/add", async (data) => {
//   const response = await axios
//     .post(`${API_BASE_URL}client/new`, data)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return response;
// });

export const getContractR = createAsyncThunk("contract/get", async (data) => {
  const response = await axios
    .get(`${API_BASE_URL}c_all?current=${data.current}&limit=${data.limit}&order=${data.order}&type=${data.type}&hr_id=${data.hr_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return { data: response, order: data.order, type: data.type };
});

// export const delClient = createAsyncThunk("ag/del", async (id) => {
//   const response = await axios
//     .delete(`${API_BASE_URL}client/delete?id=${id}`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return id;
// });

// export const updateClient = createAsyncThunk("ag/update", async (fd) => {

//   const response = await axios
//     .put(`${API_BASE_URL}client/update?id=${fd.id}`,fd.fd)
//     .then((response) => {

//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return {data:response,id:fd.id};
// });

const Contract = createSlice({
  name: "contract",
  initialState: {
    contract: [],
    printDetail:{
      type: "",
      checkArOrKu: false,
      listSelectedAr: [],
      listSelectedKu: [],
      layanidwamValue: "",
      layaniyakamValue: "",
      daqDwamValue: "",
      daqYakamValue: "",
      content:""

    },

    pagination: {
      page: 1,
      pageSize: 15,
      total: 10,
    },
    table: {
      type: "",
      order: "",
    },
    status: null,
  },
  reducers: {

    setPrintDetail: (state, action) => {
      state.printDetail = action.payload;
    }

  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(addClient.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(addClient.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.client = [...state.client, action.payload];
    //   })
    //   .addCase(addClient.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
    builder
      .addCase(getContractR.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getContractR.fulfilled, (state, action) => {
        console.log(action.payload)
        state.status = HTTP_STATUS.FULFILLED;
        state.contract = action.payload.data.data;
        state.pagination = { ...action.payload.data.pagination, ...PAGESIZEOPTION };
        state.table = {
          type: action.payload.type,
          order: action.payload.order,
        };
      })
      .addCase(getContractR.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    // builder
    //   .addCase(delClient.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(delClient.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.client = state.client.filter((item) => item.id !== action.payload);
    //   })
    //   .addCase(delClient.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
    // builder
    //   .addCase(updateClient.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(updateClient.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.client = state.client.map((item) => item.id === action.payload.id ? action.payload.data:item);

    //   })
    //   .addCase(updateClient.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
  },
});

export const { setPrintDetail } = Contract.actions;

export default Contract.reducer;
