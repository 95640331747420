import { Button, Card, Col, Collapse, DatePicker, Form, Image, Input, InputNumber, Modal, Pagination, Popconfirm, Row, Space, Table, Typography, Upload, message, theme } from "antd";
import { CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, PAGESIZEOPTION } from "../config/serverApiConfig";
import { nanoid } from "nanoid";

const { Title, Paragraph } = Typography;
function Comparission() {
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [rec, setRec] = React.useState({});
  const [update, setUpdate] = React.useState(false);

  const [Work] = Form.useForm();

  const submitWork = (value) => {
    setConfirmLoading(true);
    setLoading(true);

    let formData = new FormData();
    if (value.before !== undefined & value.before.fileList.length !== 0) {
      formData.append("before", value.before.fileList[0].originFileObj);
      formData.append("after", value.before.fileList[0].originFileObj);
    }
    formData.append("work", JSON.stringify({ titleKu: "جوانکاری لووت", titleAr: "جوانکاری لووت", titleEn: "جوانکاری لووت", year: value.year }));

    if (update) {
      axios.put(`${API_BASE_URL}workshowcase/${rec.id}`, formData).then((res) => {
        message.success("کارەکەت بەسەرکەوتویی گورانکاریکرا");
        setConfirmLoading(false);
        setVisible(false);
        setLoading(false);
        setData({
          data: data.data.map((item) => (item.id === rec.id ? res.data : item)),
          pagination: data.pagination,
        });
      });
    } else {
      axios
        .post(`${API_BASE_URL}workshowcase`, formData)
        .then((res) => {
          message.success("کارەکەت بەسەرکەوتویی زیادکرا");
          Work.resetFields();
          setConfirmLoading(false);

          console.log(data,res.data,"❤️❤️❤️😂😂")
          setData({
            data: [res.data, ...data.data],
            pagination: data.pagination,
          });
          setVisible(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error("هەڵەیەک هەیە لە زیادکردنی کارەکان");
        });
    }
  };

  const [data, setData] = React.useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  const handleOnChange = useCallback((pagination, filter, sorter) => {
    setLoading(true);

    axios
      .get(`${API_BASE_URL}blogs`, {
        params: {
          page: pagination.current,
          size: pagination.pageSize,
        },
      })
      .then((res) => {
        setData({
          data: res.data.data,
          pagination: {
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: res.data.total,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

 
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const handleChange = (page, pageSize) => {};

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleDelete = (i) => {
    axios
      .delete(`${API_BASE_URL}workshowcase/${i.id}`)
      .then((res) => {
        message.success("پرسیارەکەت بەسەرکەوتویی سڕایەوە");
        setData({
          data: data.data.filter((item) => item.id !== i.id),
          pagination: data.pagination,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە سڕینەوەی پرسیارەکان");
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}workshowcase?size=1000`)
      .then((res) => {

        console.log(res.data,"🙂❤️❤️❤️");
        setData({
          data: res.data.content,
          pagination: {
            current: res.data.pageable.pageNumber + 1,
            pageSize: res.data.pageable.pageSize,
            total: res.data.totalElements,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEdit = (i) => {
    setUpdate(true);
    setVisible(true);
    Work.setFieldsValue({
      titleKu: i.titleKu,
      titleAr: i.titleAr,
      titleEn: i.titleEn,
      year: i.year,
    });
    setRec(i);
  };

 
  return (
    <div style={{ margin: 10, padding: 10 }}>
      <Row style={{ marginTop: 10, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding }} justify={"space-between"}>
        <Col>
          <Title level={4}>کارەکان</Title>
        </Col>

        <Col>
          <Button onClick={() => setVisible(true)} type="primary">
            زیادکردن
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        {data.data.map((item) => {
          return (
            <Col key={nanoid(3)} style={{ marginInline: 6 }} xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
              <div style={{ backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding, width: "100%" }}>
                <Row justify={"space-between"}>
                  <Title level={5}>{item.titleKu}</Title>
                  <div>
                    <Popconfirm title="ئایا دڵنیایت لە سڕینەوەی ئەم کارە؟" onConfirm={() => handleDelete(item)} okText="بەڵێ" cancelText="نەخێر">
                      <Button type="link" danger>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                    <Button onClick={() => handleEdit(item)} type="link">
                      <EditOutlined />
                    </Button>
                  </div>
                </Row>

                <Row justify={"space-around"}>
                  <Image className="object-contain" width={100} height={120} src={item.befor} />
                  <Image className="object-contain" width={100} height={120} src={item.after} />
                </Row>
              </div>
              <Paragraph style={{ margin: 5 }}>{item.year}</Paragraph>
            </Col>
          );
        })}
      </Row>

      <Row justify={"end"}>
        <Pagination pageSizeOptions={PAGESIZEOPTION} onChange={handleChange} style={{ marginTop: 20 }} current={1} total={4} />
      </Row>

      <Modal
        onOk={() => Work.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setUpdate(false);
          Work.resetFields();
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی کار
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
          
            if (name === "JPS") {
              submitWork(values);
            }
          }}
        >
          <Form name="JPS" form={Work} layout="horizontal">
            <Title level={5}>جۆری کار</Title>
          

            <Form.Item rules={[{ required: true, message: "" }]} style={{ marginBottom: "8px", marginTop: 0 }} label={"ساڵ"} labelCol={{ span: 5 }} name={"year"}>
              <InputNumber placeholder={"ساڵ"} />
            </Form.Item>
            <Title level={5}>وێنە</Title>

            <Form.Item rules={[{ required: true, message: "" }]} style={{ marginBottom: "8px", marginTop: 0 }} label={"پێشتر"} labelCol={{ span: 5 }} name={"before"}>
              <Upload listType="picture-card" maxCount={1}  accept="image/*" customRequest={dummyRequest}>
                Click to Upload
              </Upload>
            </Form.Item>

            
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
}

export default Comparission;
