import { Avatar, Button, Card, Col, Form, Input, InputNumber, Modal, Row, Select, Tabs, Typography, Upload, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import axios from "axios";
import { API_BASE_URL } from "../config/serverApiConfig";
import { getBase64 } from "../utils/history";


const { Title, Paragraph } = Typography;

function Hr() {
  const [visable, setVisable] = useState(false);
  const [key, setKey] = useState(-1);
  //const selector = useSelector(state => state.hr.hrList)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translation = useTranslation();
  const { useToken } = theme;
  const { token } = useToken();
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [hr, setHr] = React.useState([]);
  const [selectedId, setSelectedId] = useState("all");

  const [imageUrl, setImageUrl] = useState("https://st4.depositphotos.com/4329009/19956/v/450/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg");
  const handleUploadChange = (e) => {
    getBase64(e.file.originFileObj, (url64) => {
      setImageUrl(url64);
    });
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const filterData = (i) => {
    if (selectedId === "all") {
      return i;
    }
    // else {
    //   "lawyer" === selectedId && i
    // }
  };

  const handleFilterClick = (id) => {
    setSelectedId(id);
  };

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [hrForm] = Form.useForm();
  const submitHr = (e) => {
    setConfirmLoading(true);
    const { image, ...lawyer } = e;

    const fd = new FormData();
    if (e.image !== undefined && e.image.fileList.length !== 0) {
      fd.append("image", e.image.fileList[0].originFileObj);
    }

    fd.append("user", JSON.stringify(lawyer));

    axios.post(`${API_BASE_URL}user`, fd).then((res) => {
      console.log(res.data)
      setHr([...hr, res.data]);
      setOpen(false);
      setConfirmLoading(false);
    })
    .catch((err) => {
      console.log(err);

    })
  };

  useEffect(() => {
    axios.get(`${API_BASE_URL}user`).then((res) => {
      setHr(res.data);
    });
  }, []);



  const cancelHrModal = () => {
    setOpen(false);
  };


  const colorDiv = (color) => {
    console.log(color.role);
    switch (color.role) {
      case "lab":
        return "rgba(42, 157, 143, .7)";
      case "application":
        return "rgba(0, 0, 0, .7)";
      case "note":
        return "rgba(0, 0, 143, .7)";
      case "admin":
        return "rgba(42, 157, 0, .7)";

        case "Secretary":
          return "rgba(60, 30, 80, .7)";
    }
  };

  return (
    <div className="slb2">
      <Row align={"top"} justify={"space-between"}>
        <Col>
          <Title level={4}>تەواوی کارمەندان</Title>
        </Col>
        <Col>
          <Button onClick={() => setOpen(true)} type="primary">
            زیادکردنی کارمەند
          </Button>
        </Col>
      </Row>

      <Row>
        <div onClick={() => handleFilterClick("all")} style={{ justifyContent: "center", borderRadius: 5, alignItems: "center", display: "flex", backgroundColor: token.colorBgContainer, padding: 6, margin: "10px 10px", width: 120 }}>
          <Paragraph className="animatedColor" style={{ fontWeight: 500, margin: "auto", color: "all" === selectedId ? "#AF9931" : token.colorText }} level={5}>
            هەمووی
          </Paragraph>
        </div>

        <div onClick={() => handleFilterClick("lab")} style={{ justifyContent: "center", borderRadius: 5, alignItems: "center", display: "flex", backgroundColor: token.colorBgContainer, padding: 6, margin: "10px 10px", width: 120 }}>
          <Paragraph className="animatedColor" style={{ fontWeight: 500, margin: "auto", color: "lawer" === selectedId ? "#AF9931" : token.colorText }} level={5}>
            تاقیگە
          </Paragraph>
        </div>
        <div onClick={() => handleFilterClick("admin")} style={{ justifyContent: "center", borderRadius: 5, alignItems: "center", display: "flex", backgroundColor: token.colorBgContainer, padding: 6, margin: "10px 10px", width: 120 }}>
          <Paragraph className="animatedColor" style={{ fontWeight: 500, margin: "auto", color: "admin" === selectedId ? "#AF9931" : token.colorText }} level={5}>
            ئەدمین
          </Paragraph>
        </div>
        <div onClick={() => handleFilterClick("application")} style={{ justifyContent: "center", borderRadius: 5, alignItems: "center", display: "flex", backgroundColor: token.colorBgContainer, padding: 6, margin: "10px 10px", width: 120 }}>
          <Paragraph className="animatedColor" style={{ fontWeight: 500, margin: "auto", color: "accountant" === selectedId ? "#AF9931" : token.colorText }} level={5}>
            ئەپپڵیکەیشن
          </Paragraph>
        </div>
        <div onClick={() => handleFilterClick("note")} style={{ justifyContent: "center", borderRadius: 5, alignItems: "center", display: "flex", backgroundColor: token.colorBgContainer, padding: 6, margin: "10px 10px", width: 120 }}>
          <Paragraph className="animatedColor" style={{ fontWeight: 500, margin: "auto", color: "consultant" === selectedId ? "#AF9931" : token.colorText }} level={5}>
            تێبینی
          </Paragraph>
        </div>

        <div onClick={() => handleFilterClick("Secretary")} style={{ justifyContent: "center", borderRadius: 5, alignItems: "center", display: "flex", backgroundColor: token.colorBgContainer, padding: 6, margin: "10px 10px", width: 120 }}>
          <Paragraph className="animatedColor" style={{ fontWeight: 500, margin: "auto", color: "Secretary" === selectedId ? "#AF9931" : token.colorText }} level={5}>
            سکرتێر
          </Paragraph>
        </div>
      </Row>

      <Row justify={"space-around"}>
        {hr
          .filter((i) => {
            if (selectedId === "all") {
              return i;
            } else if (selectedId === i.role) {
              return i;
            }
          })
          .map((i) => {
            return (
              <Col onClick={() => navigate(`/HR/${i.id}`)} className="my-5" style={{ backgroundColor: token.colorBgContainer, boxShadow: token.boxShadow, borderRadius: token.borderRadius, padding: token.paddingMD }} xs={23} xl={5} xxl={7} sm={23} md={11} lg={7}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <Avatar size={"large"} style={{ width: 60, height: 60 }} shape="circle" src={i.image ? i.image : "https://images.unsplash.com/photo-1679486227887-cfebf2af6246?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI4fHRvd0paRnNrcEdnfHxlbnwwfHx8fA%3D%3D"} />
                    <div style={{ display: "flex", flexDirection: "column", marginInline: 10 }}>
                      <h1 style={{ color: token.colorText,fontSize:20 }}>ناو : {i.name}</h1>
                      <p style={{ color: "#999" }}>چالاک</p>
                    </div>
                  </div>
                  <div style={{ backgroundColor: colorDiv(i), borderRadius: token.borderRadius, paddingInline: 6, paddingBlock: 2 }}>
                    <p style={{ color: "white", fontSize: 12 }}>{i.role}</p>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>

      <Modal
        onOk={() => hrForm.submit()}
        open={open}
        centered
        okText="زیادکردن"
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={cancelHrModal}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی خەرجی
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitHr(values);
            }
          }}
        >
          <Form name="AWS" form={hrForm} layout="horizontal">
            <Row justify="center">
              <Form.Item name={"image"} style={{ margin: "0px 0" }}>
                <Upload style={{ margin: 0 }} listType="picture" customRequest={dummyRequest} showUploadList={false} beforeUpload={beforeUpload} onChange={handleUploadChange}>
                  <img src={imageUrl} style={{ width: 100, margin: 0, height: 100, objectFit: "cover", borderRadius: 60 }} />
                </Upload>
              </Form.Item>
            </Row>
            <Form.Item />

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"ناوی کارمەند"} labelCol={{ span: 5 }} name={"name"}>
              <Input placeholder={"ناوی کارمەند"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px" }} label={"ناوی بەکارهێنەر"} labelCol={{ span: 5 }} name={"username"}>
              <Input placeholder={"ناوی بەکارهێنەر"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px" }} label={"ژم. مۆبایل"} labelCol={{ span: 5 }} name={"phoneNumber"}>
              <Input placeholder={"ژم. مۆبایل"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px" }} label={"ڕەگەز"} labelCol={{ span: 5 }} name={"gender"}>
              <Select placeholder="ڕەگەز">
                <Select.Option value={"نێر"}>نێر</Select.Option>
                <Select.Option value={"مێ"}>مێ</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px" }} label={"ڕۆڵ"} labelCol={{ span: 5 }} name={"role"}>
              <Select placeholder="ڕۆڵ">
                <Select.Option value={"note"}>تێبینی</Select.Option>

                <Select.Option value={"admin"}>ئەدمین</Select.Option>

                <Select.Option value={"application"}>ئەپپڵیکەیشن</Select.Option>

                <Select.Option value={"lab"}>تاقیگە</Select.Option>
                <Select.Option value={"Secretary"}>سکرتێر</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item labelCol={{ span: 5 }} style={{ marginBottom: "8px" }} label={"تەمەن"} name={"age"}>
              <InputNumber  />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
              name={"password"}
              label="وشەی نهێنی"
            >
              <Input.Password placeholder="وشەی نهێنی" />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>

      {/* <Modal open={multipleDelete} onCancel={cancelMultipleDelete} onOk={deleteMultipleExpense} title={"ئایە دڵنیایت لە سڕینەوەی دیاریکراوەکان؟"} okText={"بەڵێ"} centered okType="danger" cancelText={"نەخێر"}>
        <div style={{ height: 40 }}></div>
      </Modal> */}
    </div>
  );
}

export default Hr;
