import { Col, Row, Typography } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

const { Title } = Typography;

function Tutorial() {
  const authSelector = useSelector((state) => state.auth.auth);

  return (
    <div style={{ margin: 20, padding: 10 }}>
      <Title level={4}>ڤیدۆیی فێرکاری</Title>
      <div className="grid xs:grid-cols-1 place-content-center md:grid-cols-3 xl:grid-cols-4 gap-3">
        {authSelector.role === "admin" && (
          <div>
            <ReactPlayer width={300} height={300} url="https://youtu.be/GYZPgH0QJu8" />
          </div>
        )}

        {authSelector.role === "admin" && (
          <div>
            <ReactPlayer width={300} height={300} url="https://youtu.be/RGViQw61jnM" />
          </div>
        )}

        {(authSelector.role === "admin" || authSelector.role === "lab") && (
          <div>
            <ReactPlayer width={300} height={300} url="https://youtu.be/qAK7CIxEK3Y" />
          </div>
        )}

        {(authSelector.role === "admin" || authSelector.role === "application") && (
          <div>
            <ReactPlayer width={300} height={300} url="https://youtu.be/MBCA75SRGIk" />
          </div>
        )}

        {(authSelector.role === "admin" || authSelector.role === "Secretary") && (
          <div>
            <ReactPlayer width={300} height={300} url="https://youtu.be/UaSLnuAhZAE" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Tutorial;
