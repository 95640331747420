import { configureStore,combineReducers,compose,applyMiddleware } from '@reduxjs/toolkit'
import clientSlice from './slices/client.slice'
import UtilSlice from './slices/util.slice'
import AuthSlice from './slices/authentication.slice'
import matterSlice from './slices/matter.slice'
import expenseSlice from './slices/expense.slice'
import invoiceSlice from './slices/invoice.slice'
import agSlice from './slices/ag.slice'
import contractSlice from './slices/contract.slice'





export const store = configureStore({
    reducer:{
        clients:clientSlice,
        util:UtilSlice,
        auth:AuthSlice,
        matter:matterSlice,
        expense:expenseSlice,
        invoice:invoiceSlice,
        ag:agSlice,
        Contract:contractSlice
    }
})


