import { PrinterOutlined,CheckCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography, message, theme,Upload,Form } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PDFDownloadLink } from "@react-pdf/renderer";
import rudawFont from "../../styles/Fonts/rudaw.ttf";

import { BlobProvider, Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../config/serverApiConfig";
import Check3 from "../../styles/check2.png";
import Cli from "../../styles/jjj.png";
import Quixote from "./Quixote";



const { Title, Paragraph } = Typography;

Font.register({
  family: "rudaw",
  src: rudawFont,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "rudaw",
    fontSize: 11,
    // paddingTop: 5,

    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: "100%",

    marginLeft: "auto",
    marginRight: "auto",
  },
});

const colorPrint = "#333";

function InvoiceDetail({type,other,clishe}) {



  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const [NoteForm] = Form.useForm();
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const submitWork = (value) => {
    setConfirmLoading(true);
    let formData = new FormData();

    if (value.upload !== undefined && value.upload.fileList.length !== 0) {
      formData.append("image", value.upload.fileList[0].originFileObj);
    }
    else{
      setConfirmLoading(false)
      return message.warning("تکایە وێنەیەک هەڵبژێرە")
    }

    axios
      .post(`${API_BASE_URL}lab?p_id=${other.p_id}`, formData)
      .then((res) => {
        setVisible(false);
        setConfirmLoading(false);


         axios.put(`${API_BASE_URL}lab/result/${id}`,{check:"true"}).then((res1) => {
     
      setTimeout(() => {
        console.log(res1.data)
        navigate(-1)
        message.success("✔✔")
      },400)
    })
    .catch((err) => {
      console.log(err)
      message.error("❌❌")
    })
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە زیادکردنی تێبینی");
      });
  };

 

  const { id, invoiceId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const { useToken } = theme;
  const dispatch = useDispatch();
  const { token } = useToken();
  const [invoice, setInvoice] = React.useState(true);


  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = React.useState(false);
  const authSelector = useSelector((state) => state.auth);

 

  const handleOkDelete = () => {
    setOpenDelete(false);

    let ex_id = invoice.expenses.map((i) => i.id);
    axios.post(`${API_BASE_URL}invoice/delete?invoice_id=${id}&hr_name=${authSelector.auth.name}`, { me_ids: ex_id, matter_id: invoice.m_id }).then((res) => {
      message.success("پسوڵە سڕایەوە");
      navigate(-1);
    });
  };

 
  const MyDocument = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ position: "relative" }}>
          <Image style={styles.logo} src={Cli} />

          <View style={{ position: "absolute", bottom: 55, right: 10 }}>
            <Text style={{ width: 400, textAlign: "right", fontSize: 18 }}>پاکێجی پشکنینی تایبەت بە ئەنجامدانی </Text>
            <Text style={{ width: 400, textAlign: "right", fontSize: 18 }}>
              <Text style={{ color: "red", fontSize: 18, marginHorizontal: 4 }}> {" "}{other.discount} % {" "}</Text> نەشتەرگەری جوانکاری لوت بە داشکاندنی : 
            </Text>
          </View>
        </View>

        <View style={{ width: "100%", paddingHorizontal: 8, alignItems: "flex-end" }}>
          <Text style={{ color: "#222", fontSize: 16 }}>{other.name} ناو : </Text>
          <View style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
            <Text style={{ color: "#222", fontSize: 14, marginRight: 60 }}>{other.date ?dayjs(other.date).format("YYYY-MM-DD"):"" } بەروار  : </Text>

            <Text style={{ color: "#222", fontSize: 14 }}>ساڵ {" "}{other.age }تەمەن  : </Text>
          </View>
        </View>

        <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", marginTop: 20 }}>

          {type.map((i) => (
               <View  key={i.id} style={{ width: "100%", flexDirection: "row", justifyContent: "space-around", marginTop: 20 }}>
               <View style={{ width: "45%", height: 20, flexDirection: "row", alignItems: "center", display: "flex", justifyContent: "center" }}>
                 <View style={{ border: "1px solid #1C8B85", width: 30, height: 30 }}>
                  {i.check1 && <Image src={Check3} style={{ width: 25, height: 25 }} />}

                 </View>
                 <Text style={{ width: 80, margin: 0, padding: 0, marginHorizontal: 10 }}>{i.name1}</Text>
               </View>
               <View style={{ width: "45%", height: 20, flexDirection: "row", alignItems: "center", display: "flex", justifyContent: "center" }}>
                 <View style={{ border: "1px solid #1C8B85", width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {i.check2 && <Image src={Check3} style={{ width: 25, height: 25 }} />}
                 </View>
                 <Text style={{ width: 80, margin: 0, padding: 0, marginHorizontal: 10 }}>{i.name2}</Text>
               </View>
             </View>

          ))}
       

         
        
        </View>

        <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 50, marginTop: 50, width: 130 }}>
          <View style={{ height: 1, backgroundColor: "#1C8B85", width: "100%" }}></View>
          <Text style={{ color: "#1C8B85", marginTop: 5, textAlign: "center" }}>Doctor's signature</Text>
        </View>

        {/* <Footer  /> */}

        <View style={{ position: "absolute", bottom: 0, left: 0, right: 0, backgroundColor: "#1C8B85", display: "flex", justifyContent: "center", height: 30, alignItems: "center" }}>
          <Text style={{ color: "#fff", fontSize: 12 }}>سلێمانی-شەقامی توویمەلیک-سەروو فولکەی کاوە السلیمانیة - شارع توی ملیک - قرب فلکة کاوة</Text>
        </View>
      </Page>
    </Document>
  );

  const checkResult = () => {
    setVisible(true)
   
  }

  return (
<>    
<Modal
        onOk={() => NoteForm.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setUpdate(false);
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی ئەنجامی تاقیگە
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitWork(values);
            }
          }}
        >
          <Form name="AWS" form={NoteForm} layout="horizontal">
            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"وێنە"} labelCol={{ span: 5 }} name={"upload"}>
              <Upload listType="picture-card" maxCount={1} accept="image/*" customRequest={dummyRequest}>
                Click to Upload
              </Upload>
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
  <Modal centered title={"ئایە دڵنیایت لە سڕینەوەی ئەم پسوڵەیە؟"} open={openDelete} onOk={handleOkDelete} onCancel={() => setOpenDelete(false)} okText={"بەڵێ"} cancelText={"نەخێر"}></Modal>

      <Row justify={clishe ? "space-between":"end"}>
        <Col>
          {invoice && (
            <PDFDownloadLink document={MyDocument} fileName={"invoice.pdf"}>
              {({ blob, url, loading, error }) => (
                <Button type="primary">
                  {loading ? "چاوەڕوان بە!" : `پرێنت `}
                  <PrinterOutlined />{" "}
                </Button>
              )}
            </PDFDownloadLink>
          )}
        </Col>

        {clishe && (
          
        
      
        <Col>
          <Button onClick={checkResult}  type="default">
                تەواوبوون <CheckCircleOutlined />
            </Button>


        </Col>
          )}
      </Row>

      <Row justify={"center"}>
        <Col style={{ backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius }} xs={24}>
          {invoice && (
            <BlobProvider document={MyDocument}>
              {({ blob, url, loading, error }) => {
                
                if (blob) {


                  return <Quixote blob={blob} />;
                }

                if (error) {
                  return error;
                }

                return <div>The PDF is rendering...</div>;
              }}
            </BlobProvider>
          )}
        </Col>
      </Row>
</>  );
}

export default InvoiceDetail;
