import { Button, Col, DatePicker, Form, Image, Input, Modal, Pagination, Row, Select, Space, Typography, Upload, message, theme } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL, PAGESIZEOPTION } from "../config/serverApiConfig";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { NotePagination } from "../slices/invoice.slice";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import {DeleteOutlined} from '@ant-design/icons';
import dayjs from "dayjs";
import { nanoid } from "nanoid";

const { Title, Paragraph } = Typography;

function Notes() {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const fetchRef = useRef(0);
  const [deleteId, setDeleteId] = useState(null);

  const [NotesForm] = Form.useForm();
  const [searchLoading, setSearchLoading] = useState(false);
  const [notesArray, setNotesArray] = useState([]);

  const [data, setData] = useState([]);
  const selector = useSelector((state) => state.invoice.pagination);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitWork = (value) => {
    setConfirmLoading(true);
    let formData = new FormData();
    if (value.upload !== undefined && value.upload.fileList.length !== 0) {
      formData.append("image", value.upload.fileList[0].originFileObj);
    }

    // formData.append("patient", JSON.stringify({ id: value.name }));

    if (value.name === undefined) {
      axios
        .post(`${API_BASE_URL}patient/note/multi?date=${dayjs(value.date).format("YYYY-MM-DD")}`, formData)
        .then((res) => {
          console.log(res.data);
           setData([res.data, ...data]);
          setConfirmLoading(false);
          setVisible(false);
          NotesForm.resetFields()

        })
        .catch((err) => {
          console.log(err);
          message.error("هەڵەیەک هەیە لە زیادکردنی تێبینی");
        });
    } else {
      
      axios.post(`${API_BASE_URL}patient/note/multi?p_id=${value.name}&date=${dayjs(value.date).format("YYYY-MM-DD")}`,formData).then((res) => {
                
          // setData([res.data, ...data]);
          setConfirmLoading(false);
          setVisible(false);
          NotesForm.resetFields()
        })
        .catch((err) => {
          message.error("هەڵەیەک هەیە لە زیادکردنی تێبینی");
        });
    }
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChange = useCallback(async (pagination, filter, sorter) => {
    axios.get(`${API_BASE_URL}patient/note?page=${pagination}`).then((res) => {
      setData(res.data.content);
      dispatch(
        NotePagination({
          total: res.data.totalElements,
          page: res.data.number+1,
          pageSize: res.data.size,
        })
      );
    });
  }, []);

  useEffect(() => {

    axios.get(`${API_BASE_URL}patient/note?page=${selector.page }`).then((res) => {
      setData(res.data.content);
      console.log(res.data);
      dispatch(
        NotePagination({
          total: res.data.totalElements,
          page: res.data.number+1,
          pageSize: res.data.size,
        })
      );
    });
  }, []);

  const memoSearch = React.useMemo(() => {
    const loadFunction = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setNotesArray([]);
      setSearchLoading(true);

      axios.post(`${API_BASE_URL}patient/search`, { searchKey: value }).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          console.log("fetch abort", newOptions);
          return;
        }
        let newP = newOptions.data.map((item) => ({ label: item.fullName, value: item.id }));

        console.log("fetch success", newOptions.data);

        setNotesArray(newP);
        setSearchLoading(false);
      });
    };

    return debounce(loadFunction, 800);
  }, [notesArray, 800]);

  const finishDate = (value) => {
    axios.post(`${API_BASE_URL}patient/note/search`, { start: dayjs(value.range).format("YYYY-MM-DD"), end: dayjs(value.range).format("YYYY-MM-DD") }).then((res) => {
      setData(res.data);
    });
  };


  const handleProcessDelete = (item) => {

    setDeleteVisible(true);
    setDeleteId(item.id);

    console.log(item.id);



  }

  const handleActionDelete = () => {

    setDeleteLoading(true);
    axios.delete(`${API_BASE_URL}patient/note/${deleteId}`).then((res) => {
      setData(data.filter((item) => item.id !== deleteId));
      setDeleteLoading(false);
      setDeleteVisible(false);
      message.success("تێبینی بەسەرکەوتوی سڕایەوە");

    }).catch((err) => {
      console.log(err);
      message.error("هەڵەیەک هەیە لە سڕینەوەی تێبینی");
    });

  }

  const { useToken } = theme;
  const { token } = useToken();
  return (
    <div style={{ margin: 20, padding: 10 }}>
      <Row justify={"space-between"}>
        <Col xs={12}>
          <Title level={4}>تێبینی</Title>
        </Col>

        <Col style={{ display: "flex", justifyContent: "end" }} xs={12}>
          <Button style={{ marginRight: "auto" }} onClick={() => setVisible(true)} type="primary">
            زیادکردن
          </Button>
        </Col>
      </Row>

      <Form onFinish={finishDate} className="flex flex-row" layout="inline" >

       

     
        <Form.Item className="flex-22 md:w-[400px]" name={"range"}>
          <DatePicker className="w-full md:w-[400px]"  />
        </Form.Item>

        <Button className="md:flex-initial flex-1" htmlType="submit" type="default">
          گەڕان
        </Button>
       
      </Form>

      <Row style={{ marginTop: 20 }}>
        {data.length > 0 &&
          data.map((item) => (
            <Col key={nanoid(3)} style={{ marginTop: 0, borderRadius: 6, padding: 20, backgroundColor: token.colorBgContainer, flexDirection:'column', display: "flex",justifyContent:'center',alignItems:'center' }} xs={24}>

              <div className="absolute left-0 top-0">
              <Button onClick={() =>handleProcessDelete(item)} danger type="link">
                 <DeleteOutlined />
              </Button>
              </div>
              <Image width={200} src={item.image} />
              <div style={{ marginInline: 8, marginTop: 2 }}>
                <Title style={{ margin: 0, color: "#006D77" }} level={4}>
                  {item.date}
                </Title>
                {item.patients?.map((ele, index) => {
                  return (
                    <Button key={nanoid(3)} type="link" onClick={() => navigate(`/patient/${ele.id}`)} style={{ display: "inline", margin: 0 }}>
                      {" "}
                      {ele.fullName}{" "}
                    </Button>
                  );
                })}
              </div>
            </Col>
          ))}
      </Row>

      <Row justify={"end"}>
        <Pagination  onChange={handleChange} style={{ marginTop: 20 }} total={selector.total} current={selector.page } pageSize={selector.pageSize} />
      </Row>



{/* Modal Delete Confirmation */}
      <Modal
        title="تێبینی سڕینەوە"
        open={deleteVisible}
        onOk={handleActionDelete}
        onCancel={() => setDeleteVisible(false)}
        okText="بەڵێ"
        centered
        loading={deleteLoading}
        cancelText="لابردن"
      >
        <p>ئایا دڵنیایت کە دەتەوێت ئەم تێبینییە بسڕیتەوە؟</p>
      </Modal>
      







      <Modal
        onOk={() => NotesForm.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setUpdate(false);
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی تێبینی
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitWork(values);
            }
          }}
        >
          <Form name="AWS" form={NotesForm} layout="horizontal">
      
            <Form.Item initialValue={dayjs()} style={{ marginBottom: "8px", marginTop: 0 }} label={"بەروار"} labelCol={{ span: 5 }} name={"date"}>
              <DatePicker />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"تێبینی"} labelCol={{ span: 5 }} name={"upload"}>
              <Upload listType="picture-card" maxCount={1} accept="image/*" customRequest={dummyRequest}>
                Click to Upload
              </Upload>
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
}

export default Notes;
