import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { API_BASE_URL, HTTP_STATUS, PAGESIZEOPTION } from "../config/serverApiConfig";
import { changeBillable } from "./matter.slice";
import { updateField } from "./matter.slice";

export const addExpense = createAsyncThunk("Expense/add", async (data) => {

  console.log(data,"✅✅🤍🤍🤍😅😅🎃🎃")
  const response = await axios
    .post(`${API_BASE_URL}matter/expense/new?matter_id=${data.id}&hr_name=${data.name}`, data.data)
    .then((response) => {
      console.log(response.data)
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const getExpenseR = createAsyncThunk("Expense/get", async (data) => {

  const response = await axios
    .get(`${API_BASE_URL}expense?current=${data.current}&limit=${data.limit}&order=${data.order}&status=${data.status}&hr_id=${data.hr_id}`)
    .then((response) => {
 
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });



  return {data:response,order:data.order,status:data.status};
});

export const getExpenseSoloR = createAsyncThunk("Expense/getSolo", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}matter/expense?matter_id=${id}`)
    .then((response) => {
      console.log(response.data)
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const delExpense = createAsyncThunk("Expense/del", async (id) => {
  const response = await axios
    .delete(`${API_BASE_URL}matter/expense/delete?id=${id.id}&matter_id=${id.matter_id}&hr_name=${id.name}`)
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return id.id;
});

export const updateExpenseR = createAsyncThunk("Expense/update", async (data) => {
    console.log(data)

  const response = await axios
    .patch(`${API_BASE_URL}matter/expense/update?id=${data.id}&hr_name=${data.name}&matter_id=${data.matterId}`, data.data)
    .then((response) => {
      console.log(response,"ssss")
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });


    console.log(response)
  return { data: response, id: data.id };
});

const ExpenseSlice = createSlice({
  name: "expense",
  initialState: {
    expense: [],
    soloExpense: [],
    table:{
      status:[],
      order:""
    },
    pagination: {
      page: 1,
      pageSize: 15,
      total: 10,
    },
    status: null,
  },
  reducers: {
    deleteExpenseOnSolo:(state,action) => {
      state.soloExpense = state.soloExpense.filter((item) => item.id !== action.payload);
    },
    AddExpenseOnSolo:(state,action) => {
      state.soloExpense = [...state.soloExpense, action.payload];
      

    },
    updateExpenseOnSolo:(state,action) => {
      state.soloExpense = state.soloExpense.map((item) => (item.id === action.payload.id ? action.payload.data : item));
    },
    deleteMultipleR:(state,action) => {
      let i = 0;
      for (i; i < action.payload.length; i++) {
        
        state.expense = state.expense.filter((item) => item.id !== action.payload[i]);

      }
    },
    
    
    
    

  },
  extraReducers: (builder) => {
    builder
      .addCase(addExpense.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(addExpense.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.soloExpense = [...state.soloExpense,action.payload]
        
        state.expense = [...state.expense, action.payload];
      })
      .addCase(addExpense.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(getExpenseR.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getExpenseR.fulfilled, (state, action) => {

        state.status = HTTP_STATUS.FULFILLED;
        state.expense = action.payload.data.data        ;
        state.table = {
          order:action.payload.order,
          status:action.payload.status,

        }

        state.pagination = {...action.payload.data.pagination,...PAGESIZEOPTION};
      })
      .addCase(getExpenseR.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(delExpense.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(delExpense.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.expense = state.expense.filter((item) => item.id !== action.payload);

        state.soloExpense = state.soloExpense.filter((item) => item.id !== action.payload);

      })
      .addCase(delExpense.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(updateExpenseR.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(updateExpenseR.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.expense = state.expense.map((item) => (item.id === action.payload.id ? action.payload.data : item));
        state.soloExpense = state.soloExpense.map((item) => (item.id === action.payload.id ? action.payload.data : item));
      })
      .addCase(updateExpenseR.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });

    builder
      .addCase(getExpenseSoloR.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getExpenseSoloR.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.soloExpense = action.payload;
        //deleteOnSolo()
      })
      .addCase(getExpenseSoloR.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
  },
});


export const { deleteOnSolo,deleteMultipleR } = ExpenseSlice.actions

export default ExpenseSlice.reducer;
