import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL, HTTP_STATUS } from "../config/serverApiConfig";

export const exhangeGet = createAsyncThunk("exchange/get", async () => {
  const response = await axios
    .get(`${API_BASE_URL}dollar`)
    .then((response) => {
      return response.data.dollar;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const exchangePost = createAsyncThunk("exchange/Post", async (data) => {
  const response = await axios
    .patch(`${API_BASE_URL}dollar/update`, { dollar: data})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
});

const UtilSlice = createSlice({
  name: "utils",
  initialState: {
    theme: "light" || localStorage.getItem("theme"),
    compact: "compact" || localStorage.getItem("compact"),
    language: "en" || localStorage.getItem("language"),
    size: "large" || localStorage.getItem("tableSize"),
    dollar: 0,
    privacy: false || localStorage.getItem("privacy"),
    status: null,
  },
  reducers: {
    updateTheme: (state, action) => {

     
      localStorage.setItem("theme", action.payload);
      state.theme = action.payload;
    },
    updateLanguage: (state, action) => {
      localStorage.setItem("language", action.payload);
      console.log(action.payload);
      state.language = action.payload;
    },
    updateCompact: (state, action) => {
      localStorage.setItem("compact", action.payload);
      state.compact = action.payload;
    },

    updatePrivacy: (state, action) => {
      localStorage.setItem("privacy", action.payload);

      state.privacy = action.payload;
    },

    tableSize: (state, action) => {
      localStorage.setItem("tableSize", action.payload);
      state.size = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(exchangePost.pending, (state, action) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(exchangePost.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.dollar = action.payload;
      })
      .addCase(exchangePost.rejected, (state, action) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(exhangeGet.pending, (state, action) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(exhangeGet.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.dollar = action.payload;
      })
      .addCase(exhangeGet.rejected, (state, action) => {
        state.status = HTTP_STATUS.REJECTED;
      });
  },
});

export const { updateTheme, updateCompact, updateLanguage, tableSize,updatePrivacy } = UtilSlice.actions;

export default UtilSlice.reducer;
