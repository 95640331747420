import { Button, Card, Col, Collapse, DatePicker, Form, Input, Modal, Popconfirm, Row, Space, Table, Typography, message, theme } from "antd";
import { CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/serverApiConfig";

const { Title, Paragraph } = Typography;
function Blog() {
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [rec, setRec] = React.useState({});
  const [update, setUpdate] = React.useState(false);

  const [Work] = Form.useForm();

  const submitWork = (value) => {
    setConfirmLoading(true);
    setLoading(true);

    if (update) {
      axios.put(`${API_BASE_URL}blogs/${rec.id}`, value).then((res) => {
        console.log(res.data);
        message.success("بڵۆگەکەت بەسەرکەوتویی گورانکاریکرا");
        setConfirmLoading(false);
        setVisible(false);
        setLoading(false);
        setData({
          data: data.data.map((item) => (item.id === rec.id ? res.data : item)),
          pagination: data.pagination,
        });
      });
    } else {
      axios
        .post(`${API_BASE_URL}blogs`, value)
        .then((res) => {
          message.success("بڵۆگەکەت بەسەرکەوتویی زیادکرا");
          Work.resetFields();
          setConfirmLoading(false);
          setData({
            data: [res.data, ...data.data],
            pagination: data.pagination,
          });
          setVisible(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error("هەڵەیەک هەیە لە زیادکردنی بڵۆگەکان");
        });
    }
  };

  const [data, setData] = React.useState({
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  const handleOnChange = useCallback((pagination, filter, sorter) => {
    setLoading(true);

    axios
      .get(`${API_BASE_URL}blogs`, {
        params: {
          page: pagination.current,
          size: pagination.pageSize,
        },
      })
      .then((res) => {
        setData({
          data: res.data.data,
          pagination: {
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: res.data.total,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const panelStyle = {
    marginBottom: 24,
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;



  const handleDelete = (i) => {
    axios
      .delete(`${API_BASE_URL}blogs/${i.id}`)
      .then((res) => {
        console.log(res.data);
        message.success("پرسیارەکەت بەسەرکەوتویی سڕایەوە");
        setData({
          data: data.data.filter((item) => item.id !== i.id),
          pagination: data.pagination,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە سڕینەوەی پرسیارەکان");
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}blogs`)
      .then((res) => {
        setData({
          data: res.data.content,
          pagination: {
            current: res.data.pageable.pageNumber + 1,
            pageSize: res.data.pageable.pageSize,
            total: res.data.totalElements,
          },
        });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEdit = (i) => {
    setUpdate(true);
    setVisible(true);
    Work.setFieldsValue({
      titleKU: i.titleKU,
      titleAR: i.titleAR,
      titleEN: i.titleEN,
      youtubeLink: i.youtubeLink,
    });
    setRec(i);
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <Row justify={"space-between"}>
          <Col>
            <Paragraph>This is panel header 1</Paragraph>
          </Col>
          <Col>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
            <Button type="link">
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      ),
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <Row justify={"space-between"}>
          <Col>
            <Paragraph>This is panel header 1</Paragraph>
          </Col>
          <Col>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
            <Button type="link">
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      ),
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <Row justify={"space-between"}>
          <Col>
            <Paragraph>This is panel header 1</Paragraph>
          </Col>
          <Col>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
            <Button type="link">
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      ),
      children: <p>{text}</p>,
      style: panelStyle,
    },
  ];
  return (
    <div style={{ margin: 10, padding: 10 }}>
      <Row style={{ marginTop: 10, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding }} justify={"space-between"}>
        <Col>
          <Title level={4}>بڵۆگەکان</Title>
        </Col>

        <Col>
          <Button onClick={() => setVisible(true)} type="primary">
            زیادکردن
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: 10, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding }}>
        <Col xs={24}>
          <Table
            columns={[
              {
                title: "سەردێر کوردی",
                dataIndex: "titleKU",
                key: "titleKu",
              },
              {
                title: "سەردێر عەرەبی",
                dataIndex: "titleAR",
                key: "titleAr",
              },
              {
                title: "سەردێر ئینگلیزی",
                dataIndex: "titleEN",
                key: "titleEn",
              },
              {
                title: "ڤیدیۆ",
                dataIndex: "youtubeLink",
                key: "video",
                render: (text, record) => (
                  <a href={text} target="_blank" rel="noopener noreferrer">
                    <Button type="link">بینین</Button>
                  </a>
                ),
              },
              {
                title: "کردارەکان",
                dataIndex: "action",
                key: "action",
                render: (text, record) => (
                  <Space>
                    <Button onClick={() => handleEdit(record)} type="link">
                      <EditOutlined />
                    </Button>
                    <Popconfirm title="ئایە دڵنیایت لە سڕینەوەی ئەم پرسیارە؟" onConfirm={() => handleDelete(record)} okText="بەڵێ" cancelText="نەخێر">
                      <Button danger type="link">
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </Space>
                ),
              },
            ]}
            scroll={{
              x: 1000,
            }}
            dataSource={data.data}
            pagination={data.pagination}
            onChange={handleOnChange}
          />
        </Col>
      </Row>

      <Modal
        onOk={() => Work.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setUpdate(false);
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی بڵۆگ
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitWork(values);
            }
          }}
        >
          <Form name="AWS" form={Work} layout="horizontal">
            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"سەردێر کوردی"} labelCol={{ span: 5 }} name={"titleKU"}>
              <Input placeholder={"سەردێر کوردی"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"سەردێر عەرەبی"} labelCol={{ span: 5 }} name={"titleAR"}>
              <Input placeholder={"سەردێر عەرەبی"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"سەردێر ئینگلیزی"} labelCol={{ span: 5 }} name={"titleEN"}>
              <Input placeholder={"سەردێر ئینگلیزی"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"لینک"} labelCol={{ span: 5 }} name={"youtubeLink"}>
              <Input placeholder={"لینک"} />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
}

export default Blog;
