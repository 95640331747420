import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Spin } from "antd";
import SiderDemo from "./Components/Menu";
import Hr from "./Components/Hr";
import Signin from "./pages/Auth/Signin";
import Report from "./pages/Report";
import Settings from "./pages/Settings";
import { startUp } from "./slices/authentication.slice";
import HrDetail from "./pages/hrDetail";
import { exhangeGet } from "./slices/util.slice";
import AuthType from "./pages/Auth/Permission";
import Patient from "./pages/Patient";
import PatientDetail from "./Components/client/PatientDetail";
import Notes from "./pages/Notes";
import LabPage from "./pages/LabPage";
import Blog from "./pages/Blog";
import Faq from "./pages/Faq";
import Comparission from "./pages/Comparission";
import NewFaq from "./pages/NewFaq";
import Cliche from "./pages/Cliche";
import UpcomeTest from "./pages/UpcomeTest";
import Instruction from "./pages/Instruction";
import Tutorial from "./pages/Tutorial";
import InstructionText from "./pages/instructionText";

function App() {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.auth);

  useEffect(() => {
    async function firstLoad() {
      await dispatch(exhangeGet());
      dispatch(startUp());
    }

    firstLoad();
  }, []);

  if (!selector.status) {
    return <Spin style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50,-50%)" }} />;
  } else {
  return (
    <Routes>
      <Route path="/Login" element={<Signin />} />

      {selector.auth.id ? (
        <Route path="/" element={<SiderDemo />}>

          <Route element={<AuthType allowedRoles={["admin", "lab","Secretary"]} />}>
            <Route index path="/Patient" element={<Patient />} />
          </Route>

          <Route path="/Patient/:id" element={<PatientDetail />} />
          <Route element={<AuthType allowedRoles={["admin"]} />}>
            <Route path="/HR" element={<Hr />} />
          </Route>

          

          <Route path="/HR/:id" element={<HrDetail />} />

          <Route element={<AuthType allowedRoles={["admin", "note"]} />}>
            <Route path="/Note" element={<Notes />} />
          </Route>

          <Route element={<AuthType allowedRoles={["admin", "lab","Secretary"]} />}>
            <Route path="/LabPage" element={<LabPage />} />
          </Route>

          <Route element={<AuthType allowedRoles={["admin"]} />}>
            <Route path="/Report" element={<Report />} />
          </Route>

          <Route element={<AuthType allowedRoles={["application", "admin"]} />}>
            <Route path="/Camparission" element={<Comparission />} />
          </Route>

          <Route element={<AuthType allowedRoles={["application", "admin"]} />}>
            <Route path="/" element={<Patient />} />
          </Route>

          <Route element={<AuthType allowedRoles={["application", "admin"]} />}>
            <Route path="/Blog" element={<Blog />} />
          </Route>

          <Route element={<AuthType allowedRoles={["application", "admin"]} />}>
            <Route path="/FAQ" element={<Faq />} />
          </Route>

          <Route element={<AuthType allowedRoles={["application", "admin"]} />}>
            <Route path="/InstructionVideos" element={<Instruction />} />
          </Route>

          <Route element={<AuthType allowedRoles={["application", "admin"]} />}>
            <Route path="/InstructionText" element={<InstructionText />} />
          </Route>

          <Route path="/FAQ/:id" element={<NewFaq />} />
          <Route path="/Tutorial" element={<Tutorial />} />
          <Route path="/Cliche" element={<Cliche />} />
          <Route path="/Cliche/:id" element={<UpcomeTest />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/FAQ/new" element={<NewFaq />} />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/Login" />} />
      )}
    </Routes>
  );
  }
}

export default App;