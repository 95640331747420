import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { API_BASE_URL, HTTP_STATUS, PAGESIZEOPTION } from "../config/serverApiConfig";

export const addClient = createAsyncThunk("clients/add", async (data) => {
  const response = await axios
    .post(`${API_BASE_URL}client/new`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const getClient = createAsyncThunk("client/get", async (data) => {
  console.log(data, "😥😥😥😁😁😥😥")
  const response = await axios
    .get(`${API_BASE_URL}client?current=${data.current}&limit=${data.limit}&order=${data.order}&f_name=${data.f_name}&l_name=${data.l_name}&type=${data.type}&c_id=${data.c_id}&hr_id=${data.hr_id}`)
    .then((response) => {
      console.log(response.data, "😥😥😥😁😁😥😥");
      return response.data;
    })

    .catch((error) => {
      console.log(error);
    });

  return { data: response, order: data.order, f_name: data.f_name, l_name: data.l_name, type: data.type,c_id:data.c_id };
});

export const delClient = createAsyncThunk("client/del", async (id) => {
  const response = await axios
    .delete(`${API_BASE_URL}client/delete?id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return id;
});

export const getClientSoloC = createAsyncThunk("client/solo", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}client/solo?id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
});

export const updateClient = createAsyncThunk("client/update", async (fd) => {
  const response = await axios
    .put(`${API_BASE_URL}client/update?id=${fd.id}`, fd.fd)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return { data: response, id: fd.id };
});

export const getCardBillableC = createAsyncThunk("client/card", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}client/cards?client_id=${id}`)
    .then((response) => {
      console.log(response.data, "hhhhhhhhhhhhh");
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const getClientMatter = createAsyncThunk("client/matter", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}client/matter?client_id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

// export const getCardUnpaidC = createAsyncThunk("client/unpaid", async (id) => {
//   const response = await axios
//     .get(`${API_BASE_URL}client/card/invoice?client_id=${id}`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return response;
// });
// export const getCardCreditC = createAsyncThunk("client/credit", async (id) => {
//   const response = await axios
//     .get(`${API_BASE_URL}client/card/refund?client_id=${id}`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return response;
// });

// export const getCardSumC = createAsyncThunk("client/sum", async (id) => {
//   const response = await axios
//     .get(`${API_BASE_URL}client/card/sum?client_id=${id}`)
//     .then((response) => {
//       console.log("Sum",response.data)
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return response;
// });

const ClientSlice = createSlice({
  name: "clients",
  initialState: {
    client: [],
    billable: 0,
    unpaid: 0,
    credit: 0,
    sum: 0,
    lawyer_total: 0,
    gshty: 0,
    clientSolo: {},
    clientMatter: [],
    c_id:"",
    type: 0,
    f_name: "",
    l_name: "",
    order: "DESC",
    filterTableTitle: ["ئایدی", "وێنە", "ناوی سیانی", "ناوی دووەم", "ژ.مۆبایل", "ژم.مۆبایل ت", "جۆر", "بەرواری دروست کردن", "ئیمێڵ","تەمەن", "کردار"],

    pagination: {
      current: 1,
      pageSize: 15,
      total: 10,
    },
    status: null,
  },
  reducers: {
    updateNameColumn: (state, action) => {
      
      if (action.payload.type === "add") {
        state.filterTableTitle = state.filterTableTitle.concat(action.payload.data);
      } else {
        state.filterTableTitle = state.filterTableTitle.filter((i) => i !== action.payload.data);
      }
    },
    PatientPagination: (state, action) => {
      state.pagination ={
        current: action.payload.pageable.pageNumber+1,
        pageSize: action.payload.size,
        total: action.payload.totalElements,
      };
      state.order = action.payload.sort;
    },
    incrementTotal: (state, action) => {
      state.pagination.total = state.pagination.total + 1;
    } 

  },
  extraReducers: (builder) => {
    builder
      .addCase(addClient.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(addClient.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.client = [...state.client, action.payload];
      })
      .addCase(addClient.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(getClient.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        
        state.status = HTTP_STATUS.FULFILLED;
        state.client = action.payload.data[0];
        state.pagination = { ...action.payload.data[1][0], ...PAGESIZEOPTION };
        state.type = action.payload.type;
        state.f_name = action.payload.f_name;
        state.l_name = action.payload.l_name;
        state.order = action.payload.order;
        state.c_id = action.payload.c_id
      })
      .addCase(getClient.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(delClient.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(delClient.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.client = state.client.filter((item) => item.id !== action.payload);
      })
      .addCase(delClient.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(getClientMatter.pending, (state) => {})
      .addCase(getClientMatter.fulfilled, (state, action) => {
        state.clientMatter = action.payload;
      })
      .addCase(getClientMatter.rejected, (state) => {});
    builder
      .addCase(getClientSoloC.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getClientSoloC.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.clientSolo = action.payload;
      })
      .addCase(getClientSoloC.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(updateClient.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        console.log(action.payload, "✅✅✅✅🎉🎉🎉🎉🎉🎉🎉");
        state.status = HTTP_STATUS.FULFILLED;
        state.client = state.client.map((item) => (item.id === action.payload.id ? action.payload.data : item));
        state.clientSolo = action.payload.data;
      })
      .addCase(updateClient.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder.addCase(getCardBillableC.fulfilled, (state, action) => {
      state.billable = action.payload.billable === null ? 0 : action.payload.billable;
      state.unpaid = action.payload.invoice === null ? 0 : action.payload.invoice;
      state.credit = action.payload.credit === null ? 0 : action.payload.credit;
      state.sum = action.payload.wargyraw === null ? 0 : action.payload.wargyraw;
      state.lawyer_total = action.payload.lawyer_total === null ? 0 : action.payload.lawyer_total;
      state.gshty = action.payload.gshty === null ? 0 : action.payload.gshty;
    });
  },
});

export const { updateExpenseCard, updateNameColumn,PatientPagination,incrementTotal } = ClientSlice.actions;

export default ClientSlice.reducer;
