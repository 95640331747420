//import React, { useState, useContext } from "react";
import LocalizedStrings from "react-localization";
import { useSelector } from 'react-redux';
import localization from "../localization";

const useTranslation = (props) => {
 
  const lang = useSelector(state => state.util.language);

  let translation = new LocalizedStrings(localization);

  translation.setLanguage(lang);
  return translation;
}




export default useTranslation;