export default {
  login:"تسجيل الدخول",
  email:"البريد الإلكتروني",
  password: "كلمة المرور",
  name: "اسم",
  RememberMe: "تذكرنى",
  HelloAgain: "مرحبا مجددا!",
  softwareName: "قانون ارف",
  InputEmail: 'الرجاء إدخال البريد الإلكتروني الخاص بك!',
  InputPass: "الرجاء إدخال كلمة المرور الخاصة بك!",
  InputFlatRate: "الرجاء إدخال السعر الثابت الخاص بك!",
  InputCurrency: "الرجاء إدخال عملتك!",
  InputSubject: "الرجاء إدخال موضوعك!",
  InputStart: "الرجاء إدخال بدايتك!",
  InputExpense: "الرجاء تحديد المصاريف!",
  InputAmount: "الرجاء إدخال المبلغ الخاص بك!",
  TodaySum: "ملخص اليوم",
  today: "اليوم",
  lab: "مختبر",
  Matter: "قضيه",
  patient: "مريض",
  Expense: "مصروف",
  Activity: "نشاط",
  Invoice: "فاتورة",
  Dashboard: "لوحة القيادة",
  HrManagement: "إدارة الموارد البشرية",
  Report: "تقرير",
  Contract: "عقد",
  Contact: "صاحب العمل",
  Language: "لغة",
  Logout: "تسجيل خروج",
  Settings: "إعدادات",
  Deposit: "إيداع الأموال",
  DueBalance: "الرصيد المستحق",
  QuickCreate: "إنشاء سريع.",
  DailyUpdate: "التحديث اليومي",
  Exchange: "تبادل",
  Update: "تحديث",
  Calculator: "آلة حاسبة",
  NoNotification: "لا يوجد إشعارات",
  New:"جديد",
  RecentContact:"الاتصال الأخير",
  Name:"اسم",
  Age:"عمر",
  Phone:"هاتف",
  FirstName:"الاسم الاول",
  LastNAme:"اسم العائلة",
  Home:"مسكن",
  Note:"ملحوظة",
  Refresh:"ينعش",
  AddNew:"اضف جديد",
  Search:"يبحث",
  list:"قائمة",
  Paid:"مدفوع",
  Due:"بسبب",
  Billable:"قابل للفوترة",
  CreatedAt:"أنشئت فيt",
  Info:"معلومات",
  Delete:"حذف",
  Cancel:"إلغاء",
  Back:"خلف",
  Doyouwantdelete:"هل تريد حذف",
  DoyouwantSave:"هل تريد حفظ",
  Start:"بداية",
  End:"نهاية",
  FlatRate:"سعر موحد",
  Currency:"عملة",
  IQD:"د.ع",
  DOLLAR:"دولار",
  Completed:"مكتمل",
  NotCompleted:"غير مكتمل",
  Input:"الرجاء إدخال",
  Subject:"موضوعات",
  Status:"حالة",
  Add:"يضيف",
  Date:"تاريخ",
  Description:"وصف",
  Action:"عمل",
  Save:"يحفظ",
  Edit:"تعديل",
  SureTo:"متأكد من " ,
  Amount:"كمية" ,
  Receipt:"استلام" ,
  Preview:"معاينة" ,
  InvoiceDate:"تاريخ الفاتورة" ,
  Sub:"المجموع" ,
  PaymentDue:"استحقاق الدفع" ,
  StillNotPaid:"لا يزال غير مدفوع" ,
  AddNewInvoice:"إضافة فاتورة جديدة" ,
  AddNewMatter:"أضف مادة جديدة" ,
  AddNewLawyer:"اضافة محامي جديد" ,
  AddNewContact:"إضافة جهة اتصال جديدة" ,
  AddNewExpense:"إضافة مصاريف جديدة" ,
  GenerateNewInvoice:"إنشاء فاتورة جديدة",
  Lawyers:"محامون",
  Profile:"الملف الشخصي",
  Appearance:"مظهر",
  Notification:"تنبيه",
  Agenda:"جدول أعمال",
  Create:"خلق",
  About:"حول",
  Qty:"الكمية",
  NewLawyer:"محامي جديد",
  BilledBy:"مفوتر بواسطة",
  LinkedTo:"مرتبط بـ",
  ClickToUpload:"انقر للتحميل",
  GenerateInvoice:"توليد الفاتورة",
  GeneratedFor:"ولدت ل",
  SelectLawyer:"حدد محامي",
  FlatFeeWinCase:"حالة فوز رسوم ثابتة",
  FlatFeeLoseCase:"حالة خسارة الرسوم الثابتة",
  Pending:"قيد الانتظار",
  Print:"مطبعة",
  SubTotal:"المجموع الفرعي:",
  SaveInvoice:"حفظ الفاتورة",
  MarkAsPaid:"وضع علامة كمدفوع",
  NewActivity:"نشاط جديد",
  NewInvoice:"فاتورة جديدة",
  NewMatter:"مسألة جديدة",
  NewExpense:"مصاريف جديدة",
  CreateANewMatter:"خلق مسألة جديدة",
  ListContact:"قائمة الإتصال",
  InfoMatter:"معلومات المسألة",
  id:"ئایدی",

};
