import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Typography, theme, Upload, Grid, Space, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import InvoiceDetail from "../Components/matter/invoiceDetail";
import { debounce, set } from "lodash";
import { API_BASE_URL } from "../config/serverApiConfig";
import axios from "axios";
import { useParams } from "react-router-dom";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import dayjs from "dayjs";

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

function LabPage() {
  const screens = useBreakpoint();
  const [sendLabLoading, setSendLabLoading] = useState(false);
  const [name, setName] = useState("");

  const [stompClient, setStompClient] = useState(null);
  const [form] = Form.useForm();
  const [formType] = Form.useForm();

  useEffect(() => {
    const socket = new SockJS(`${API_BASE_URL}socket`);
    const stomp = Stomp.over(socket);
    stomp.connect({}, () => {
      setStompClient(stomp);
      stomp.subscribe("/topic/messages", (message) => {});
    });
  }, []);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  }

  const { id } = useParams();
  const [type, setType] = React.useState([
    { id: 1, name1: "CBC", check1: false, check2: false, name2: "TSH" },
    { id: 3, name1: "Urea", check1: false, check2: false, name2: "FT3" },
    { id: 5, name1: "Creatinin", check1: false, check2: false, name2: "FT4" },
    { id: 7, name1: "Bleeding Time", check1: false, check2: false, name2: "Suger" },
    { id: 9, name1: "Clotting Time", check1: false, check2: false, name2: "HbA1c" },
    { id: 11, name1: "Viral Screen", check1: false, check2: false, name2: "PT , INR" },
    { id: 13, name1: "Blood Group", check1: false, check2: false, name2: "PTT" },
  ]);

  const [searchLoading, setSearchLoading] = useState(false);
  const [notesArray, setNotesArray] = useState([]);

  const [other, setOther] = React.useState({
    name: "",
    date: "",
    age: "",
    discount: "40",
  });

  const [actualType, setActualType] = React.useState([
    { id: 1, name1: "cbc", check1: false, check2: false, name2: "tsh" },
    { id: 3, name1: "urea", check1: false, check2: false, name2: "ft3" },
    { id: 5, name1: "creatinin", check1: false, check2: false, name2: "ft4" },
    { id: 7, name1: "bleedingTime", check1: false, check2: false, name2: "suger" },
    { id: 9, name1: "clottingTime", check1: false, check2: false, name2: "hba1c" },
    { id: 11, name1: "viralScreen", check1: false, check2: false, name2: "ptInr" },
    { id: 13, name1: "bloodGroup", check1: false, check2: false, name2: "ptt" },
  ]);

  const { useToken } = theme;
  const { token } = useToken();
  const fetchRef = useRef(0);

  const hanldeShowAndSet = (e) => {
    console.log(e)
    setOther(e);
    setActualType(type);
  };

  const memoSearch = React.useMemo(() => {
    const loadFunction = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setNotesArray([]);
      setSearchLoading(true);

      axios.post(`${API_BASE_URL}patient/search`, { searchKey: value }).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          console.log("fetch abort", newOptions);
          // for fetch callback order
          return;
        }
        let newP = newOptions.data.map((item) => ({ label: item.fullName, value: item.id }));

        console.log("fetch succe:ss", newOptions.data);

        setNotesArray(newP);
        setSearchLoading(false);
      });
    };

    return debounce(loadFunction, 800);
  }, [notesArray, 800]);

  const sendToLab = async () => {
    setSendLabLoading(true);
    const { upload, name } = form.getFieldsValue();

    const data = {
      cbc: type[0].check1,
      tsh: type[0].check2,
      urea: type[1].check1,
      ft3: type[1].check2,
      creatinin: type[2].check1,
      ft4: type[2].check2,
      bleedingTime: type[3].check1,
      suger: type[3].check2,
      clottingTime: type[4].check1,
      hba1c: type[4].check2,
      viralScreen: type[5].check1,
      ptInr: type[5].check2,
      bloodGroup: type[6].check1,
      ptt: type[6].check2,
      date: other.date,
      discount: other.discount,
      p_id: name,
    };

    stompClient.send("/app/sendMessage", {}, JSON.stringify(data));

    setTimeout(() => {
      setSendLabLoading(false);
      form.resetFields();
      formType.resetFields();
      // setActualType all check1 and check2 to false

      setActualType((prev) =>
        prev.map((i) => {
          return { ...i, check1: false, check2: false };
        })
      );

      message.success("بە سەرکەوتوویی ناردرا");
    }, 600);
  };

  return (
    <div style={{ margin: 20, padding: 10 }}>
      <Row style={{ marginTop: 10, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, boxShadow: token.boxShadow, padding: token.padding }} justify={"space-between"}>
        <Col xs={24}>
          <Title level={4}>تاقیگە</Title>
        </Col>

        <Col xs={24}>
          <Form onFinish={hanldeShowAndSet} form={form} layout={screens.xl ? "inline" : "vertical"}>
            <Form.Item style={{ width: 300 }} label={"چارەخواز"} name={"name"}>
              <Select loading={searchLoading} onSearch={memoSearch} onChange={e => console.log(e)} placeholder="چارەخواز" filterOption={false} showSearch options={notesArray} />
            </Form.Item>

            <Form.Item initialValue={dayjs()} name={"date"} label="بەروار">
              <DatePicker placeholder="بەروار" />
            </Form.Item>

            <Form.Item initialValue={40} name={"discount"} label="داشکاندن">
              <Input placeholder="داشکاندن" />
            </Form.Item>

            <Space>
              <Button loading={sendLabLoading} onClick={sendToLab}>
                ناردن بۆ تاقیگە
              </Button>

              <Button htmlType="submit" type="primary">
                بینین{" "}
              </Button>
            </Space>
          </Form>
        </Col>
        <Col xs={24}>
          <Form style={{ marginTop: 10 }} form={formType} layout="horizontal">
            <Row style={{ width: "100%" }}>
              <Col xs={8} sm={7} md={5} lg={4} xl={3}>
                <Form.Item labelCol={{ span: 20 }} name={"CBC"} label="CBC">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 1 ? { ...i, check1: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"TSH"} label="TSH">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 1 ? { ...i, check2: i2.target.checked } : i)))} />
                </Form.Item>
                <Form.Item labelCol={{ span: 20 }} name={"Urea"} label="Urea">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 3 ? { ...i, check1: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"FT3"} label="FT3">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 3 ? { ...i, check2: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"Creatinin"} label="Creatinin">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 5 ? { ...i, check1: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"FT4"} label="FT4">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 5 ? { ...i, check2: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"Bleeding Time"} label="Bleeding Time">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 7 ? { ...i, check1: i2.target.checked } : i)))} />
                </Form.Item>
              </Col>

              <Col xs={8} sm={7} md={5} lg={4} xl={3}>
                <Form.Item labelCol={{ span: 20 }} name={"Suger"} label="Suger">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 7 ? { ...i, check2: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"Clotting Time"} label="Clotting Time">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 9 ? { ...i, check1: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"HbA1c"} label="HbA1c">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 9 ? { ...i, check2: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"Viral Screen"} label="Viral Screen">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 11 ? { ...i, check1: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"PT , INR"} label="PT , INR">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 11 ? { ...i, check2: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"Blood Group"} label="Blood Group">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 13 ? { ...i, check1: i2.target.checked } : i)))} />
                </Form.Item>

                <Form.Item labelCol={{ span: 20 }} name={"PTT"} label="PTT">
                  <Checkbox onChange={(i2) => setType((prev) => prev.map((i) => (i.id === 13 ? { ...i, check2: i2.target.checked } : i)))} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <InvoiceDetail type={actualType} clishe={false} other={other} />
    </div>
  );
}

export default LabPage;
