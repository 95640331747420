import { Tabs, Typography } from "antd";
import React from "react";
// import ActivityReport from "../Components/Report/Activity.Report";

const { Paragraph } = Typography;

function Report() {
  return (
    <div className="slb2">
      {/* <Tabs
        type="card"
        items={[
          {
            label: "ڕاپۆرتی تاقیگە",
            children: <ActivityReport />,
            key: "1",
          },
        ]}
      /> */}
    </div>
  );
}

export default Report;
