import React from "react";
import InvoiceDetail from "../Components/matter/invoiceDetail";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config/serverApiConfig";



function UpcomeTest() {



 
  const [type, setType] = React.useState([
    { id: 1, name1: "CBC", check1: false, check2: false, name2: "TSH" },
    { id: 3, name1: "Urea", check1: false, check2: false, name2: "FT3" },
    { id: 5, name1: "Creatinin", check1: false, check2: false, name2: "FT4" },
    { id: 7, name1: "Bleeding Time", check1: false, check2: false, name2: "Suger" },
    { id: 9, name1: "Clotting Time", check1: false, check2: false, name2: "HbA1c" },
    { id: 11, name1: "Viral Screen", check1: false, check2: false, name2: "PT , INR" },
    { id: 13, name1: "Blood Group", check1: false, check2: false, name2: "PTT" },
  ]);

  const [other, setOther] = React.useState({
    name: "",
    date: "",
    age: "",
    discount: "",
    p_id:0
  });

  const [tt2, setTt2] = React.useState({
    bleedingTime: false,
    bloodGroup: false,
    clottingTime: false,
    viralScreen: false,
    bloodGroup: false,
    cbc: false,
    urea: false,
    creatinin: false,
    tsh: false,
    ft3: false,
    ft4: false,
    suger: false,
    hba1c: false,
    pt: false,
    ptt: false,
    discount: "",
    date: "",
    ptInr: false,
    patient: {},
  });
  const { id } = useParams();
  const [data, setData] = React.useState(null);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}uncheck/res/${id}`)
      .then((res) => {



        setOther({
          name: res.data.patient.fullName,
          date: res.data.date,
          age: res.data.patient.age,
          discount: res.data.discount,
          p_id:res.data.patient.id
        });

        setType([
          {
            id: 1,
            name1: "CBC",
            check1: res.data.cbc,
            check2: res.data.tsh,
            name2: "TSH",
          },
          {
            id: 3,
            name1: "Urea",
            check1: res.data.urea,
            check2: res.data.ft3,
            name2: "FT3",
          },
          {
            id: 5,
            name1: "Creatinin",
            check1: res.data.creatinin,
            check2: res.data.ft4,
            name2: "FT4",
          },
          {
            id: 7,
            name1: "Bleeding Time",
            check1: res.data.bleedingTime,
            check2: res.data.suger,
            name2: "Suger",
          },
          {
            id: 9,
            name1: "Clotting Time",
            check1: res.data.clottingTime,
            check2: res.data.hba1c,
            name2: "HbA1c",
          },
          {
            id: 11,
            name1: "Viral Screen",
            check1: res.data.viralScreen,
            check2: res.data.pt,
            name2: "PT , INR",
          },
          {
            id: 13,
            name1: "Blood Group",
            check1: res.data.bloodGroup,
            check2: res.data.ptt,
            name2: "PTT",
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return <div style={{margin:20,padding:20}}>
    
    <InvoiceDetail type={type} other={other} clishe={true}  />

    
  </div>;
}

export default UpcomeTest;
