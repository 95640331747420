import Icon, { CopyrightCircleOutlined, GlobalOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Form, Input, Menu, Space, Typography, theme } from 'antd';
import React, { useState } from 'react';
import ParticleBackground from 'react-particle-backgrounds';
import { useDispatch, useSelector } from 'react-redux';

import { Navigate, useNavigate } from "react-router-dom";
import useTranslation from '../../customHooks/translations';
import { signin } from '../../slices/authentication.slice';
import { updateLanguage, updateTheme } from '../../slices/util.slice';


const { Title,Paragraph } = Typography;





export const Dark = () => (
  <svg width="15" height="15" viewBox="0 0 24 24" fill="#333" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 21C9.5 21 7.375 20.125 5.625 18.375C3.875 16.625 3 14.5 3 12C3 9.5 3.875 7.375 5.625 5.625C7.375 3.875 9.5 3 12 3C12.2333 3 12.4627 3.00833 12.688 3.025C12.9127 3.04167 13.1333 3.06667 13.35 3.1C12.6667 3.58333 12.121 4.21233 11.713 4.987C11.3043 5.76233 11.1 6.6 11.1 7.5C11.1 9 11.625 10.275 12.675 11.325C13.725 12.375 15 12.9 16.5 12.9C17.4167 12.9 18.2583 12.6957 19.025 12.287C19.7917 11.879 20.4167 11.3333 20.9 10.65C20.9333 10.8667 20.9583 11.0873 20.975 11.312C20.9917 11.5373 21 11.7667 21 12C21 14.5 20.125 16.625 18.375 18.375C16.625 20.125 14.5 21 12 21Z" fill="#333"/>
  </svg>
  
    );
  
    export const Light = () => (
      <svg width="15" height="15" viewBox="0 0 24 24" fill="#f2f2f2" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21C9.5 21 7.375 20.125 5.625 18.375C3.875 16.625 3 14.5 3 12C3 9.5 3.875 7.375 5.625 5.625C7.375 3.875 9.5 3 12 3C12.2333 3 12.4627 3.00833 12.688 3.025C12.9127 3.04167 13.1333 3.06667 13.35 3.1C12.6667 3.58333 12.121 4.21233 11.713 4.987C11.3043 5.76233 11.1 6.6 11.1 7.5C11.1 9 11.625 10.275 12.675 11.325C13.725 12.375 15 12.9 16.5 12.9C17.4167 12.9 18.2583 12.6957 19.025 12.287C19.7917 11.879 20.4167 11.3333 20.9 10.65C20.9333 10.8667 20.9583 11.0873 20.975 11.312C20.9917 11.5373 21 11.7667 21 12C21 14.5 20.125 16.625 18.375 18.375C16.625 20.125 14.5 21 12 21ZM12 19C13.4667 19 14.7833 18.596 15.95 17.788C17.1167 16.9793 17.9667 15.925 18.5 14.625C18.1667 14.7083 17.8333 14.775 17.5 14.825C17.1667 14.875 16.8333 14.9 16.5 14.9C14.45 14.9 12.704 14.1793 11.262 12.738C9.82067 11.296 9.1 9.55 9.1 7.5C9.1 7.16667 9.125 6.83333 9.175 6.5C9.225 6.16667 9.29167 5.83333 9.375 5.5C8.075 6.03333 7.021 6.88333 6.213 8.05C5.40433 9.21667 5 10.5333 5 12C5 13.9333 5.68333 15.5833 7.05 16.95C8.41667 18.3167 10.0667 19 12 19Z" fill="#f2f2f2"/>
      </svg>
      
        );
  

const SignIn = (props) => {

  const [cred,setCred] = useState({username:'',password:''});
  const dispatch = useDispatch();
  //const lang = useSelector(state => state.util.language);
  const Auth = useSelector(state => state.auth.auth);
  const translation = useTranslation();
  const { useToken } = theme
  const { token } = useToken();
 
  console.log(Auth.id,"jsjsjs")
  const lang = useSelector(state => state.util);






  const settings = {
    particle: {
      particleCount: 20,
      color: "#f4f4f4",
      minSize: 15,
      maxSize: 35
    },
    velocity: {
      minSpeed: 0.2,
      maxSpeed: 0.3
    },
    opacity: {

      maxOpacity: 0.6,
     
    }
  }

  const changeLanguage = (id) => {
    let language = '';
    if(id.key === '1') language="en";
    if(id.key === '2') language="ar";
    if(id.key === '3') language="ku";
    dispatch(updateLanguage(language))
  }

  const navigate = useNavigate(); 


  const loginTrigger = (e) => {
    dispatch(signin(cred))
    
    
   
 
  }


  const menu = (
    <Menu
    onClick={changeLanguage}
      items={[
        
            {
              label: "en",
              key: '1',
              style:{textAlign:'center'},

              
            },
            {
              label: 'ar',
              key: '2',
              style:{textAlign:'center'}
            },
            {
              label: 'ku',
              key: '3',
              style:{textAlign:'center'}
            }
         
      ]}
    />
  );




  switch (Auth.role) {

    case 'admin':
      return <Navigate to="/Patient" />;
   
    case 'lab':
      
      return <Navigate to="/Patient" />;
    

    case 'Secretary':
     
      return <Navigate to="/Patient" />;

  }
  // if (Auth.id) 
   

  return (
    <div className='signin'>  

        <div  style={{position:'absolute',height:'100vh',width:'100vw',overflow:'hidden',backgroundColor:token.colorPrimary}}>
        <ParticleBackground settings={settings}/>
        </div>
        <div style={{position:'absolute',right:"3%",top:'3%'}}>
            <Space>
                <Button style={{border:0,display:'flex'}} onClick={() => dispatch(updateTheme(lang.theme === 'light'?'dark':'light'))} type='link'>
              
        {lang.theme === 'light' ? <Icon style={{margin:'auto'}} component={Light} />:<Icon style={{margin:'auto'}}    component={Dark} />}
      
                  
                    
                    </Button>
        <Dropdown overlay={menu} placement="bottom">
        <Button style={{color:'#fff'}}  type='link'><GlobalOutlined /></Button>
      </Dropdown>
      </Space>
        </div>





          <div style={{backgroundColor:token.colorBgContainer}} className="white-box com">
        <div>
          <Title  style={{margin:'10px 0'}} level={2}>{translation.softwareName}</Title>
          <Title style={{margin:'0',textAlign:'center',color:token.colorTextSecondary}} level={4}>{translation.HelloAgain}</Title>
          </div>
          <Form
      name="normal_login"
      
      autoComplete='off'
      style={{width:'60%'}}
      initialValues={{
        remember: true,
      }}
      onFinish={loginTrigger}
    >
      <Form.Item
        name="username"
        
        rules={[
          {
            required: true,
            message: translation.InputEmail,
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={translation.email}
        onChange={(e) => setCred({...cred,username:e.target.value})}
        />
      </Form.Item>
      <Form.Item
    
        name="password"
        rules={[
          {
            required: true,
            message:translation.InputPass,
          },
        ]}
   
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder={translation.password}
          onChange={(e) => setCred({...cred,password:e.target.value})}
        />
      </Form.Item>
      <Form.Item> 
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>{translation.RememberMe}</Checkbox>
        </Form.Item>

      </Form.Item>

      <Form.Item style={{textAlign:'center'}}>
        <Button  style={{width:'50%'}} type="primary" htmlType="submit">
          {translation.login}
        </Button>
        
      </Form.Item>
    </Form>
          </div>

          <Paragraph style={{position:'absolute',left:'50%',bottom:'1%',transform:'translate(-50%,-50%)',color:token.colorBgBase,fontSize:14}}>AVOZ <CopyrightCircleOutlined /> {new Date().getFullYear().toString()}</Paragraph>
      </div>
  );
};




  



export default SignIn;