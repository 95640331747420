import { Button, Col, Form, message, Modal, Tag, Row, Table, Typography, theme, Select, Spin, Space, Input, DatePicker, Card, Popconfirm } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { nanoid } from "nanoid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../config/serverApiConfig";
import { useReactToPrint } from "react-to-print";
import { PrinterOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Title, Paragraph } = Typography;

function Visit() {
  const { id } = useParams();
  const [activity, setActivity] = useState(false);
  const { useToken } = theme;
  const { token } = useToken();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [checkPayment, setCheckPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBring, setLoadingBring] = useState(false);
  const [options, setOptions] = useState([]);
  const [paymentForm] = Form.useForm();
  const soloClient = useSelector((state) => state.clients.clientSolo);
  const soloMatter = useSelector((state) => state.clients.clientMatter);
  const [metterSelected, setMetterSelected] = useState(false);
  const [visible, setVisible] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Work] = Form.useForm();

  const [printVisible, setPrintVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [printData, setPrintData] = useState([]);
  const componentRef = useRef(null);

  useEffect(() => {
    axios.get(`${API_BASE_URL}history/${id}`).then((res) => {
      setData(res.data);
    });
  }, []);

  const submitWork = (value) => {
    setConfirmLoading(true);

    axios
      .post(`${API_BASE_URL}history`, { ...value, patient: { id } })
      .then((res) => {
        setData((prev) => [...prev, res.data]);
        setVisible(false)
        setConfirmLoading(false);
      })
      .catch((err) => {
        message.error("هەڵەیەک ڕوویدا");
        setConfirmLoading(false);
      });
  };

  const deleteWork = (rec) => {
    axios.delete(`${API_BASE_URL}history/${rec.id}`).then((res) => {
      setData((prev) => prev.filter((item) => item.id !== rec.id));
    });
  };

  return (
    <div>
      <Row justify="space-around" style={{ marginTop: 0, borderRadius: 6, padding: 10, backgroundColor: token.colorBgContainer }}>
        <Col xs={24}>
          <Row justify={"space-between"}>
            <Title level={4}>سەردانکردن</Title>

            <Button onClick={() => setVisible(true)} type="primary">
              زیادکردن
            </Button>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: 15 }}>
        {data.map((item) => (
          <Col style={{ marginInline: 8 }} xs={4}>
            <Card
              title={
                <Row justify={"space-between"} align={"middle"}>
                  <Col>{dayjs(item.date).format("YYYY-MM-DD")}</Col>
                  <Col>
                  <Popconfirm onConfirm={() => deleteWork(item)} okText="بەڵێ" cancelText="نەخێر" title="دڵنیای لە سڕینەوەی ئەم داواکارییەوە؟">

                    <Button danger type="link">
                      <DeleteOutlined />
                    </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              }
            >
              {item.title}
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        onOk={() => Work.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setUpdate(false);
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی سەردانکردن
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitWork(values);
            }
          }}
        >
          <Form name="AWS" form={Work} layout="horizontal">
            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"تێبینی"} labelCol={{ span: 5 }} name={"title"}>
              <Input.TextArea placeholder={"تێبینی"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"بەروار"} labelCol={{ span: 5 }} name={"date"}>
              <DatePicker placeholder={"بەروار"} />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
}

export default Visit;
