import { Button, Card, Col, Collapse, Form, Input, Popconfirm, Row, Typography, message, theme,Image, Drawer, Space } from "antd";
import { CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/serverApiConfig";
import { set } from "lodash";

const { Title, Paragraph } = Typography;
function Faq() {
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [record, setRecord] = React.useState(null);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  

  const handleOnClose = () => {
    setOpen(false);
    setEdit(false);
    setRecord(null);
    form.resetFields();

  }


  const panelStyle = {
    marginBottom: 24,
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };


  const handleDelete = (i) => {
    axios
      .delete(`${API_BASE_URL}instructions/text/${i.id}`)
      .then((res) => {
        console.log(res.data);
        message.success("پرسیارەکەت بەسەرکەوتویی سڕایەوە");
        setData(data.filter((item) => item.id !== i.id));
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە سڕینەوەی پرسیارەکان");
      });
  };

  const handleOnFinish = (values) => {
    setConfirmLoading(true);

    if(edit){

axios.put(`${API_BASE_URL}instructions/text/${record.id}`, values)
.then((res) => {
    message.success("پرسیارەکەت بەسەرکەوتویی نوێکرایەوە");
    setData(data.map((item) => (item.id === record.id ? res.data : item)));
  handleOnClose();
  setConfirmLoading(false);

})
        
    }else{
       axios.post(`${API_BASE_URL}instructions/text`, values)
        .then((res) => {
            message.success("پرسیارەکەت بەسەرکەوتویی زیادکرا");
            setData([...data, res.data]);
            handleOnClose();
            setConfirmLoading(false);

        })
    }


  }

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}instructions/text`)
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const handleEdit = (i) => {
    setRecord(i);
    setOpen(true);
    setEdit(true);
    form.setFieldsValue({
      titleKu:   i.titleKu,
      titleAr:   i.titleAr,
      titleEn:   i.titleEn,
      contentKu: i.contentKu,
      contentAr: i.contentAr,
      contentEn: i.contentEn,
    });


  }

 
  return (
    <div style={{ margin: 10, padding: 10 }}>
      <Row style={{ marginTop: 10, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding }} justify={"space-between"}>
        <Col>
          <Title level={4}>ڕێنمایی بە نووسین</Title>
        </Col>

        <Col>
          <Button onClick={() => setOpen(true)} type="primary">
            زیادکردن
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }} justify={"start"}>
        <Col style={{ marginInline: 6 }} xs={24}>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={{
              background: token.colorBgLayout,
            }}
            items={data.map((i) => {

              console.log(i)
              return {
                key: i.id,
                label: (
                  <Row justify={"space-between"}>
                    <Col>
                      <Paragraph>{i.titleKu}</Paragraph>
                  
                    </Col>
                    <Col>
                      <Popconfirm title="ئایا دڵنیایت لە سڕینەوەی پرسیارەکەت؟" okText="بەلێ" cancelText="نەخێر" onConfirm={() => handleDelete(i)}>
                        <Button type="link" danger>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Button onClick={() =>handleEdit(i)} type="link">
                        <EditOutlined />
                      </Button>
                    </Col>
                  </Row>
                ),
                style: panelStyle,
                children: <>
                <p className="p-4 bg-gray-50">{i.contentKu}</p>
             
                </>
             
              };
            })}
          />
        </Col>
      </Row>


      <Drawer open={open}
      
      extra={
        <Space>
            <Button type="default" onClick={handleOnClose}>داخستن</Button>
            <Button loading={confirmLoading} type="primary" onClick={() =>form.submit()}>
                {edit ? "نوێکردنەوە" : "زیادکردن"}
            </Button>
        </Space>
      }
      
      onClose={handleOnClose} title="" placement="left" width={"50vw"}>
        <Form layout="vertical" form={form} onFinish={handleOnFinish}>

            <Form.Item
                label="سەردێڕ کوردی"
                name="titleKu"
                rules={[{ required: true, message: "تکایە سەردێڕ بنووسە" }]}
            >
                <Input variant="filled" placeholder="سەردێڕ کوردی" />
            </Form.Item>

            <Form.Item
                label="سەردێڕ عربی"
                name="titleAr"
                rules={[{ required: true, message: "تکایە سەردێڕ بنووسە" }]}
            >
                <Input variant="filled" placeholder="سەردێڕ عربی" />
            </Form.Item>

             <Form.Item
                label="سەردێڕ ئینگلیزی"
                name="titleEn"
                rules={[{ required: true, message: "تکایە سەردێڕ بنووسە" }]}
            >
                <Input variant="filled" placeholder="سەردێڕ ئینگلیزی" />
            </Form.Item>

          

            <Form.Item
                label="ناوەڕۆکی کوردی"
                name="contentKu"
                rules={[{ required: true, message: "تکایە سەردێڕ بنووسە" }]}
            >
                <Input.TextArea variant="filled" placeholder="ناوەڕۆکی کوردی" />
            </Form.Item>

            <Form.Item
                label="ناوەڕۆکی عربی"
                name="contentAr"
                rules={[{ required: true, message: "تکایە سەردێڕ بنووسە" }]}
            >
                <Input.TextArea variant="filled" placeholder="ناوەڕۆکی عربی" />
            </Form.Item>

            <Form.Item
                label="ناوەڕۆکی ئینگلیزی"
                name="contentEn"
                rules={[{ required: true, message: "تکایە سەردێڕ بنووسە" }]}
            >
                <Input.TextArea variant="filled" placeholder="ناوەڕۆکی ئینگلیزی" />
            </Form.Item>

            





            </Form>

        </Drawer>
    </div>
  );
}

export default Faq;
