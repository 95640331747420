import { ConfigProvider, theme, Menu, Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import App from "./App";
import { startUp } from "./slices/authentication.slice";
import { exhangeGet, updateCompact, updateLanguage, updateTheme } from "./slices/util.slice";
import dayjs from "dayjs";

function AfterIndex() {
  const selector = useSelector((state) => state.util);
  const dispatch = useDispatch();
  const { useToken } = theme;
  const { token } = useToken();

  useEffect(() => {
    let languageLocal = localStorage.getItem("language");
    let themeLocal = localStorage.getItem("theme");
    let compactLocal = localStorage.getItem("compact");

    if (themeLocal === null) {
      dispatch(updateLanguage("ku"));
      dispatch(updateTheme("light"));
      dispatch(updateCompact("compact"));
    } else {
      dispatch(updateLanguage(languageLocal));
      dispatch(updateTheme(themeLocal));
      dispatch(updateCompact(compactLocal));
    }
  }, []);

  const algorthmFn = React.useMemo(() => {
    const algorthm = [selector.theme === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm];
    if (selector.compact === "compact") {
      algorthm.push(theme.compactAlgorithm);
    }

    return algorthm;
  }, [selector]);

 
    return (
      <ConfigProvider
        theme={{
          token: { fontFamily: "myFirstFont", fontSize: 16,colorPrimary:"#006d77" },
          algorithm: algorthmFn,
          components: {
            Menu: {
              itemHoverBg: "#83c5be",
              itemSelectedBg: "#006d77",
              itemSelectedColor: "#83c5be",
              itemHoverColor: "#006d77",
              itemBorderRadius: 5,
              
              
            },
          },
        }}
        direction={selector.language === "ar" || selector.language === "ku" ? "rtl" : "ltr"}
      >
        <App />
      </ConfigProvider>
    );
  
}

export default AfterIndex;
