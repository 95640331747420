import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Space, Table, theme, Typography } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/serverApiConfig";
import useTranslation from "../../customHooks/translations";

const { Title, Paragraph } = Typography;

const Dawakari = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rec, setRec] = useState({});
  const translation = useTranslation();
  const [update, setUpdate] = useState(false);
  const [chronoState, setChronoState] = useState([]);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [Work] = Form.useForm();
  const [data, setData] = useState([]);
  const { useToken } = theme;
  const { token } = useToken();

  const submitWork = (value) => {
    setConfirmLoading(true);
    if (update) {
      axios.put(`${API_BASE_URL}patient/need/${rec.id}`, { ...value, patient: { id } }).then((res) => {
        setChronoState((prev) => prev.map((item) => (item.id === rec.id ? res.data : item)));
      }).then(() => {
        setVisible(false);
        setConfirmLoading(false);
        setUpdate(false);

      });
    } else {
      axios.post(`${API_BASE_URL}patient/need`, { ...value, patient: { id } }).then((res) => {
        
        setChronoState((prev) => [...prev, res.data]);
        setConfirmLoading(false);
        setVisible(false);
      });
    }
  };

 

  useEffect(() => {
    axios.get(`${API_BASE_URL}patient/need/${id}`).then((res) => {
      setChronoState(res.data);
    });
  }, []);

  const handleEdit = (rec) => {
    setRec(rec);
    setUpdate(true);
    setVisible(true);
    Work.setFieldsValue({
      name: rec.name,
      note: rec.note,
      date: dayjs(rec.date),
    });
  };

  const handleDelete = (rec) => {
  
    axios.delete(`${API_BASE_URL}patient/need/${rec.id}`).then((res) => {
      setChronoState((prev) => prev.filter((item) => item.id !== rec.id));
    
    });
  };

  return (
    <Row justify="space-around" style={{ marginTop: 0, borderRadius: 6, padding: 10, backgroundColor: token.colorBgContainer }}>
      <Col xs={24}>
        <Row justify={"space-between"}>
          <Title level={4}>داواکاری</Title>
          <Button onClick={() => setVisible(true)} type="primary">
            زیادکردن
          </Button>
        </Row>

        <Table
          dataSource={chronoState}
          columns={[
            {
              title: "ناو",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "تێبینی",
              dataIndex: "note",
              key: "note",
            },
            {
              title: "بەروار",
              dataIndex: "date",
              key: "date",
            },
            {
              title: "کردارەکان",
              dataIndex: "id",
              key: "id",
              render: (id, rec) => (
                <Space>
                  <Button onClick={() => handleEdit(rec)} type="link">
                    <EditOutlined />
                  </Button>
                  <Popconfirm onConfirm={() => handleDelete(rec)} okText="بەڵێ" cancelText="نەخێر" title="دڵنیای لە سڕینەوەی ئەم داواکارییەوە؟">
                    <Button danger type="link">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </Col>
      <Modal
        onOk={() => Work.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"

        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false)
          setUpdate(false)
        
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی داواکاری
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitWork(values);
            }
          }}
        >
          <Form name="AWS" form={Work} layout="horizontal">
            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"داواکاری"} labelCol={{ span: 5 }} name={"name"}>
              <Input placeholder={"داواکاری"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"تێبینی"} labelCol={{ span: 5 }} name={"note"}>
              <Input.TextArea placeholder={"تێبینی"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"بەروار"} labelCol={{ span: 5 }} name={"date"}>
              <DatePicker placeholder={"بەروار"} />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </Row>
  );
};

export default Dawakari;
