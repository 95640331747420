export default {
  login:"چونەژورەوە",
  email:"ئیمەیڵ",
  password: "وشەی نهێنی",
  name: "ناو",
  RememberMe: "مانەوە",
  HelloAgain: "بەخێربێتەوە!",
  softwareName: "د. کاروان",
  InputEmail: "!تکایە ئیمەڵەکەت بنوسە",
  InputPass: "!تکایە وشەی نهێنی بنوسە",
  InputFlatRate: "!تکایە نرخی کار بنوسە",
  InputCurrency: "!تکایە دراو دیاری بکە",
  InputSubject: "!تکایە چالاکی بنوسە",
  InputStart: "!تکایە کاتی دەستپێک دیاری بکە",
  InputExpense: "!تکایە خەرجی بنوسە",
  InputAmount: "!تکایە بڕ بنوسە",
  TodaySum: "پوختەی ڕۆژ",
  today: "ئەمڕۆ",
  Matters: "کارەکان",
  Matter: "کار",
  lab:"تاقیگە",
  patient: "چارەخواز",
  Expense: "خەرجیەکان",
  Activity: "چالاکی",
  Invoice: "پسوڵە",
  Dashboard: "داشبۆرد",
  HrManagement: "بەڕێوبردنی کارمەند",
  Report: "ڕیپۆرت",
  Contract: "گرێبەست",
  ContractStyle: "شێوەی گرێبەست",
  Contact: "خاوەن کار",
  Language: "زمان",
  Logout: "چونەدەرەوە",
  Settings: "ڕێخستن",
  Deposit: "گشتی وەرگیراو",
  DueBalance: "گشتی ماوە",
  QuickCreate: "دروستکردنی خێرا",
  DailyUpdate: "چاکردنی ڕۆژانە",
  Exchange: "ئاڵوگۆڕ",
  Update: "نوێ کردنەوە",
  Calculator: "بژمێر",
  NoNotification: "ئاگادارکردنەوەت نیە",
  New:"تازە",
  RecentContact:"خاوەن کارە تازەکان",
  Name:"ناو",
  Age:"تەمەن",
  Phone:"ژ.مۆبایل",
  FirstName:"ناوی یەکەم",
  LastNAme:"ناوی دووەم",
  Home:"ناونیشان",
  Note:"تێبینی",
  Refresh:"تازەکردنەوە",
  AddNew:"زیا کردنی تازە",
  Search:"گەڕان",
  list:"لیست",
  Paid:"دراو",
  Due:"ماوە",
  Billable:"گشتی",
  CreatedAt:"دروست کرا لە",
  Info:"زانیاری",
  Delete:"سڕینەوە",
  Cancel:"پەشیمان بونەوە",
  Back:"گەڕانەوە",
  Doyouwantdelete:"ئایە دڵنیای بیسڕیتەوە؟",
  DoyouwantSave:"ئایە دەتەوێ خەزنی بکەیت",
  Start:"دەستپێک",
  End:"کۆتا",
  FlatRate:"نرخ",
  Currency:"دراو",
  IQD:"د.ع",
  DOLLAR:"دۆلار",
  Completed:"تەواو بوە",
  NotCompleted:"تەواو نەبوە ",
  Input:"تکایە بینوسە",
  Subject:"بابەت",
  Status:"بار",
  Add:"زیاکردن",
  Date:"کات",
  Description:"تێبینی",
  Action:"کردار",
  Save:"زیاکردن",
  Edit:"چاکردن",
  SureTo:"دڵنیای بۆ " ,
  Amount:"بڕ" ,
  Receipt:"ڕەچەتە" ,
  Preview:"بینین" ,
  InvoiceDate:"ڕۆژی بڕینی پسوڵە" ,
  Sub:"گشتی" ,
  PaymentDue:"ڕۆژی درانی پسوڵە" ,
  StillNotPaid:"هێشتا نەدراوە" ,
  AddNewInvoice:"پسوڵەی نوێ" ,
  AddNewMatter:"کاری نوێ" ,
  AddNewLawyer:"پارێزەری نوێ" ,
  AddNewExpense:"خەرجی نوێ" ,
  GenerateNewInvoice:"بڕینی پسوڵە",
  Lawyers:"پاریزەر",
  Profile:"پڕۆفایل",
  Appearance:"دەرکەوتن",
  Notification:"ئاگادارکردنەکان",
  Agenda:"کارنامە",
  Create:"دروست کردن",
  About:"دەربارە",
  Qty:"دانە",
  NewLawyer:"پارێزەری نوێ",
  BilledBy:"درا لەلایەن",
  LinkedTo:"بەستنەوە",
  ClickToUpload:"کلیک بکە دانان",
  GenerateInvoice:"بڕینی پسوڵە",
  GeneratedFor:"بەستنەوە",
  SelectLawyer:"دیاریکردنی پارێزەر",
  FlatFeeWinCase:"بڕی وەرگیراو لە بردنەوە",
  FlatFeeLoseCase:"بڕی وەرگیراو لە دۆڕان",
  Pending:"ماوە",
  Print:"پرێنت",
  SubTotal:"کۆی گشتی:",
  SaveInvoice:"خەزنکردنی پسوڵە",
  MarkAsPaid:"وەک پارەدار نیشانە بکە",
  NewActivity:"چالاکی نوێ",
  NewInvoice:"پسوڵەی نوێ",
  NewMatter:"کاری نوێ",
  NewExpense:"خەرجی نوێ",
  CreateANewMatter:"دروستکردنی کاری نوێ",
  ListContact:"لیستی خاوەنکارەکان",
  AddNewContact:"خاوەن کاری نوێ" ,
  InfoMatter:"زانیاری کار",
  ContactInfo:"دەربارەی خاوەن کار",
  ExpenseList:"هەموو خەرجیەکان",
  MatterList:"هەموو کارەکان",
  hrList:"هەموو کارمەندەکان",
  ContactList:"خاوەن کارەکان",
  SaveNewClient:'زیادکردنی خاوەن کاری نوێ',
  id:"ئایدی",
  InvolveLawyer:"پارێزەری بەشداربوو",






};
