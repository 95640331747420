export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function changesDetail(data) {
  switch (data.name) {
    case "MATTER":
      if (data.action === "CREATE") {
        return "کاری دروست کرد.";
      } else if (data.action === "UPDATE") {
        return "نوێکردنەوەی کار.";
      }

    case "ACTIVITY":
      if (data.action === "CREATE") {
        return "چالاکی دروست کرد.";
      } else if (data.action === "UPDATE") {
        return "نوێکردنەوەی چالاکی.";
      } else if (data.action === "DELETE") {
        return "سڕینەوەی چالاکی.";
      }

    case "EXPENSE":
      if (data.action === "CREATE") {
        return "خەرجی دروست کرد.";
      } else if (data.action === "UPDATE") {
        return "نوێکردنەوەی خەرجی.";
      } else if (data.action === "DELETE") {
        return "سڕینەوەی خەرجی.";
      }

    case "INVOICE":
      if (data.action === "CREATE") {
        return "پسوڵەی دروست کرد.";
      } else if (data.action === "UPDATE") {
        return "نوێکردنەوەی پسوڵە.";
      } else if (data.action === "DELETE") {
        return "سڕینەوەی پسوڵە.";
      }

    case "PAYMENT INVOICE":
      if (data.action === "CREATE") {
        return "پارەدانی پسوڵەی دروست کرد.";
      } else if (data.action === "UPDATE") {
        return "نوێکردنەوەی پارەدانی پسوڵە.";
      } else if (data.action === "DELETE") {
        return "سڕینەوەی پارەدانی پسوڵە.";
      }

    case "REFUND":
      if (data.action === "CREATE") {
        return "پارەدانی پێشەکی دروست کرد.";
      } else if (data.action === "UPDATE") {
        return "نوێکردنەوەی پارەدانی پێشەکی.";
      } else if (data.action === "DELETE") {
        return "سڕینەوەی پارەدانی پێشەکی.";
      }

    case "MONEY OUT":
      if (data.action === "CREATE") {
        return "دەرکردنی پارەی ئەنجامدا";
      } else if (data.action === "UPDATE") {
        return "دەرکردنی پارەی نوێکردەوە.";
      } else if (data.action === "DELETE") {
        return "دەرکردنی پارەی سڕیەوە.";
      }

    case "SHEWA":
      if (data.action === "CREATE") {
        return "شێوەی دروست کرد.";
      } else if (data.action === "UPDATE") {
        return "نوێکردنەوەی شێوە.";
      } else if (data.action === "DELETE") {
        return "سڕینەوەی شێوە.";
      }
  }
}


export function kurdishInteger(data) {
  // for(let i = 0;i<data.length;i++){
    
  // }
  // switch (data.name) {
    




  // }

}

export const paginationTable = (option, sorter) => {
  let query = "";

  if (option !== {}) {
    let page = option.current ? "page=" + option.current : "";
    let items = option.pageSize ? "&items=" + option.pageSize : "";
    let sort = sorter ? "&sort=" + sorter.order : "";
    query = `?${page}${items}${sort}`;
  }

  return query;
};
