import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../config/serverApiConfig";
import { Row, Col, Avatar, Space, Button, theme, Typography, Modal, message, Tag, Form, Upload, Input, Select, DatePicker, Descriptions, InputNumber } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getBase64 } from "../utils/history";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { changeNameDD } from "../slices/authentication.slice";

const { Title, Paragraph } = Typography;

function HrDetail() {
  const { id } = useParams();
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState({});
  const [hrForm] = Form.useForm();
  const authSelector = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();

  const cancelMultipleDelete = () => {
    setMultipleDelete(false);
  };

  const [imageUrl, setImageUrl] = useState("https://st4.depositphotos.com/4329009/19956/v/450/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg");
  const handleUploadChange = (e) => {
    getBase64(e.file.originFileObj, (url64) => {
      setImageUrl(url64);
    });
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const submitHr = (e) => {
    setConfirmLoading(true);
    const { image3, ...lawyer } = e;

    const fd = new FormData();
    console.log(e)

    if (e.image3 !== undefined && e.image3.fileList.length !== 0) {
      fd.append("image", e.image3.fileList[0].originFileObj);
    }

    fd.append("user", JSON.stringify({ ...lawyer }));

    axios.put(`${API_BASE_URL}user/${id}`, fd).then((res) => {
      setData(res.data);
      setConfirmLoading(false);
      setOpen(false);
    });
  };

  const deleteMultipleExpense = () => {
    // const id = expenseItem.map((item) => item.id);

    axios
      .delete(`${API_BASE_URL}user/${id}`)
      .then((res) => {
        navigate(-1);
        message.success("سڕایەوە 🎉");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setMultipleDelete(false);
      });
  };

  useEffect(() => {
    axios.get(`${API_BASE_URL}user/${id}`).then((res) => {
      setData(res.data);
      hrForm.setFieldsValue({
        ...res.data,
      });
      if (res.data.image) {
        setImageUrl(res.data.image);
      }

      console.log(res.data);
    });
  }, []);
  return (
    <div className="slb2">
      <Row style={{ marginTop: 20 }} justify={"space-between"} align={"top"}>
        <Title level={4}>{data.name}</Title>
        <Space>
          <Button onClick={() => setOpen(true)} type="text">
            <EditOutlined />
          </Button>
          <Button onClick={() => setMultipleDelete(true)} danger type="text">
            <DeleteOutlined />
          </Button>
        </Space>
      </Row>
      <Row>
        <Col span={24} style={{ backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding, boxShadow: token.boxShadow, marginTop: 20 }}>
          <Avatar size={"large"} style={{ width: 150, height: 150 }} shape="circle" src={data.image ? data.image : "https://images.unsplash.com/photo-1679486227887-cfebf2af6246?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDI4fHRvd0paRnNrcEdnfHxlbnwwfHx8fA%3D%3D"} />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding, boxShadow: token.boxShadow, marginTop: 20 }}>
          <Row>
            <Col xs={23} xl={12} xxl={12} sm={23} md={23} lg={12}>
              <Descriptions column={1} bordered>
                <Descriptions.Item label="ناوی کارمەند">{data.name}</Descriptions.Item>
                <Descriptions.Item label="ناوی بەکارهێنەر">{data.username}</Descriptions.Item>
                <Descriptions.Item label="ژم. مۆبایل">{data.phoneNumber}</Descriptions.Item>
                <Descriptions.Item label="ڕەگەز">{data.gender}</Descriptions.Item>
                <Descriptions.Item label="ڕۆڵ">{data.role}</Descriptions.Item>
                <Descriptions.Item label="تەمەن">{data.age}</Descriptions.Item>
                <Descriptions.Item label="وشەی نهێنی">{data.password}</Descriptions.Item>
                <Descriptions.Item label="بار">
                  <Tag color={data.status ? "green" : "volcano"}>{data.status ? "چالاک" : "نا چالاک"}</Tag>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal open={multipleDelete} onCancel={cancelMultipleDelete} onOk={deleteMultipleExpense} title={"ئایە دڵنیایت لە سڕینەوەی پارێزەر؟"} okText={"بەڵێ"} centered okType="danger" cancelText={"نەخێر"}></Modal>

      <Modal
        onOk={() => hrForm.submit()}
        open={open}
        centered
        okText="چاکردن"
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی خەرجی
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitHr(values);
            }
          }}
        >
          <Form name="AWS" form={hrForm} layout="horizontal">
            <Row justify="center">
              <Form.Item name={"image3"} style={{ margin: "0px 0" }}>
                <Upload style={{ margin: 0 }} listType="picture" customRequest={dummyRequest} showUploadList={false} beforeUpload={beforeUpload} onChange={handleUploadChange}>
                  <img src={imageUrl} style={{ width: 100, margin: 0, height: 100, objectFit: "cover", borderRadius: 60 }} />
                </Upload>
              </Form.Item>
            </Row>
            <Form.Item />

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"ناوی کارمەند"} labelCol={{ span: 5 }} name={"name"}>
              <Input placeholder={"ناوی کارمەند"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px" }} label={"ناوی بەکارهێنەر"} labelCol={{ span: 5 }} name={"username"}>
              <Input placeholder={"ناوی بەکارهێنەر"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px" }} label={"ژم. مۆبایل"} labelCol={{ span: 5 }} name={"phoneNumber"}>
              <Input placeholder={"ژم. مۆبایل"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px" }} label={"ڕەگەز"} labelCol={{ span: 5 }} name={"gender"}>
              <Select placeholder="ڕەگەز">
                <Select.Option value={"نێر"}>نێر</Select.Option>
                <Select.Option value={"مێ"}>مێ</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px" }} label={"ڕۆڵ"} labelCol={{ span: 5 }} name={"role"}>
              <Select placeholder="ڕۆڵ">
                <Select.Option value={"note"}>تێبینی</Select.Option>

                <Select.Option value={"admin"}>ئەدمین</Select.Option>

                <Select.Option value={"application"}>ئەپپڵیکەیشن</Select.Option>

                <Select.Option value={"lab"}>تاقیگە</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item labelCol={{ span: 5 }} style={{ marginBottom: "8px" }} label={"تەمەن"} name={"age"}>
              <InputNumber />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
              name={"password"}
              label="وشەی نهێنی"
            >
              <Input.Password placeholder="وشەی نهێنی" />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
}

export default HrDetail;
