import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import AfterIndex from './AfterIndex';
import './App.css';
import * as serviceWorker from "./serviceWorker";
import { store } from './store.redux';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>
    <BrowserRouter>

    <AfterIndex />
     </BrowserRouter>
    </Provider>
 

);



serviceWorker.unregister()