import { Avatar, Button, Col, DatePicker, Form, Input, Row, message, Select, Table, Tag, theme, Typography, Upload, Tabs } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBase64 } from "../../utils/history";
import { API_BASE_URL } from "../../config/serverApiConfig";


const { Paragraph, Title } = Typography;
const { Option } = Select;

function Profile() {
    const [form] = Form.useForm();
    const { useToken } = theme;
    const { token } = useToken();
    const navigate = useNavigate();
    const selectorAuth = useSelector((state) => state.auth.auth);
    const dispatch = useDispatch();
    const [File, setFile] = useState(null);
    
    const [edit, setEdit] = React.useState(false);
    const [edit2, setEdit2] = React.useState(false);
    const [nameInput, setInput] = React.useState(selectorAuth.name);
  
    const submitHr = () => {
  
      const fd = new FormData();
      if (File !== null) {
        
        fd.append("file", File.file.originFileObj);
      }
  
       fd.append("hr", JSON.stringify({ name:nameInput }));
  
      axios.patch(`${API_BASE_URL}hr/solo/update?hr_id=${selectorAuth.id}`, fd).then((res) => {
        // dispatch(updateNameAuth({name:nameInput,img:res.data.url}))
        setEdit(false)
      });
    };
  
    const [imageUrl, setImageUrl] = useState("https://st4.depositphotos.com/4329009/19956/v/450/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg");
    const handleUploadChange = (e) => {
      setFile(e)
      setEdit2(true)
      getBase64(e.file.originFileObj, (url64) => {
        setImageUrl(url64);
      });
    };
  
    function beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
  
      return isJpgOrPng;
    }
  
    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    };
  
    const handleEdit = () => {
      setEdit(!edit);
      if (edit) {
       
        submitHr();
      }
    };
  return (
    <div>

<Row>
        <Col xs={24} style={{ backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: 20 }}>
          <Upload disabled={!edit ? true : false} style={{ margin: 0 }} listType="picture" customRequest={dummyRequest} showUploadList={false} beforeUpload={beforeUpload} onChange={handleUploadChange}>
            <img src={selectorAuth.url ? edit2 ?imageUrl  :`${API_BASE_URL}hrFiles/${selectorAuth.url}`:imageUrl} style={{ width: 100, margin: 0, height: 100, objectFit: "cover", borderRadius: 60 }} />
          </Upload>

          <div style={{ marginTop: "20px" }}>
            <label htmlFor="name">ناو :</label>
            {edit ? (
              <Input defaultValue={selectorAuth.name} onChange={(e) => setInput(e.target.value)} style={{ width: "25%", marginRight: 10 }} placeholder="ناو" />
            ) : (
              <p id="name" style={{ fontWeight: 500, display: "inline", margin: "auto" }}>
                {selectorAuth.name}
              </p>
            )}
          </div>
          <Button onClick={handleEdit} style={{ marginTop: "20px", display: "block", marginRight: "auto" }} type="primary">
            {!edit ? "گۆڕانکاری" : "چاکردن"}
          </Button>
        </Col>
      </Row>

    </div>
  )
}

export default Profile