import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Modal, Popconfirm, Row, Select, Typography, Upload, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { API_BASE_URL } from "../../config/serverApiConfig";

const { Title, Paragraph } = Typography;
const { Option } = Select;

function NotesPatient() {
  const { useToken } = theme;
  const { token } = useToken();
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState([]);
  const [rec, setRec] = useState({});
  const handleDelete = (rec) => {
    axios
      .delete(`${API_BASE_URL}patient/note/${rec.id}`)
      .then((res) => {
        setData(data.filter((item) => item.id !== rec.id));
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە سڕینەوەی تێبینی");
      });
  };

  const [NoteForm] = Form.useForm();

  const submitWork = (value) => {
    setConfirmLoading(true);
    let formData = new FormData();

    console.log(value);
    if (value.upload !== undefined && value.upload.fileList.length !== 0) {
      formData.append("image", value.upload.fileList[0].originFileObj);
    }

    // formData.append("ag", JSON.stringify({ ...all, start: ee.agentletter.start === null ? null : dayjs(ee.agentletter.start).format("YYYY-MM-DD") }));
    axios
      .post(`${API_BASE_URL}patient/note?p_id=${id}`, formData)
      .then((res) => {
        setData([...data, res.data]);
        setVisible(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە زیادکردنی تێبینی");
      });
  };

  useEffect(() => {
    axios.get(`${API_BASE_URL}patient/note/${id}`).then((res) => {
      console.log(res.data);
      
      setData(res.data);
    });
  }, []);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div>
      <Row justify={"space-between"}>
        <Title level={4}>تێبینی</Title>
        <Button onClick={() => setVisible(true)} type="primary">
          زیادکردن <PlusOutlined />{" "}
        </Button>
      </Row>

      {data.length > 0 &&
        data.map((item) => (
          <Row style={{ marginTop: 20 }}>
            <Col style={{ marginTop: 0, borderRadius: 6, padding: 10, backgroundColor: token.colorBgContainer, display: "flex" }} xs={24}>
              <Row style={{ width: "100%" }} justify={"space-between"}>
                <div>
                  <Image width={100} src={item.image} />
                  <div style={{ marginInline: 8, marginTop: 2 }}>
                    <Title style={{ margin: 0, color: "#006D77" }} level={4}>
                      {dayjs(item.date).format("DD-MM-YYYY")}
                    </Title>
                    <Paragraph style={{ display: "inline", margin: 0, color: "#888" }}>{item.patients.map((i) => i.fullName)}</Paragraph>
                  </div>
                </div>
                <div>
                  <Popconfirm onConfirm={() => handleDelete(item)} title="ئایا دڵنیایت لە سڕینەوەی تێبینی؟" okText="بەڵێ" cancelText="نەخێر">
                    <Button type="link" danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              </Row>
            </Col>
          </Row>
        ))}

      <Modal
        onOk={() => NoteForm.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setUpdate(false);
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی تێبینی
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitWork(values);
            }
          }}
        >
          <Form name="AWS" form={NoteForm} layout="horizontal">
            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"تێبینی"} labelCol={{ span: 5 }} name={"upload"}>
              <Upload listType="picture-card" maxCount={1} accept="image/*" customRequest={dummyRequest}>
                Click to Upload
              </Upload>
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
}

export default NotesPatient;
