import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { API_BASE_URL, HTTP_STATUS } from "../config/serverApiConfig";




export const signin = createAsyncThunk("user/login", async (data) => {
    
  const response = await axios
    .post(`${API_BASE_URL}user/authenticate`, data)
    .then((response) => {
      console.log(response.data,"Datai hezha ✔")
      return {
        name: response.data.name,
        id: response.data.id,
        url: response.data.url,
        role: response.data.role,
      };
    })
    .catch((error) => {
   
      if (error.response.status === 403) {
        message.error("Wrong username or password || Or Not Have Permission");
      }
      return {
        name: null,
        role: null,
        id: null,
      };
    });

  return response;
});

const AuthSlice = createSlice({
  name: "authentication",
  initialState: {
    auth: {
      name: null,
      id: null,
      role:null,
      url:""
    },
    status:false
  },
  reducers: {
    logOut: (state, action) => {
     localStorage.removeItem("auth")

      state.auth = {
        name: null,
        role: null,
        id: null,
      };
    },
    startUp: (state, action) => {
      state.status = true
      if (localStorage.getItem("auth")) {
        const user = localStorage.getItem("auth");
        state.auth = JSON.parse(user);
      } else {
        state.auth = {
          name: null,
          role: null,
          id: null,
        
        };
      }

  
    },

    changeNameDD:(state,action) => {
      state.auth = {
        ...state.auth,
        name:action.payload
      }
    },

    updateNameAuth: (state, action) => {
   
      state.auth = {
        ...state.auth,
        name:action.payload.name,
        url:action.payload.img
      }
    },


    
  },

  extraReducers:(builder) => {
    builder
    .addCase(signin.pending, (state) => {
      state.status = HTTP_STATUS.PENDING;
    })
    .addCase(signin.fulfilled, (state, action) => {
      state.status = HTTP_STATUS.FULFILLED;
      state.auth = action.payload;
      localStorage.setItem("auth", JSON.stringify(action.payload));
    })
    .addCase(signin.rejected, (state) => {
      state.status = HTTP_STATUS.REJECTED;
    });

  }
});

export const { logOut,startUp,updateNameAuth,changeNameDD  } = AuthSlice.actions;

export default AuthSlice.reducer;
