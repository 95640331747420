import { Menu } from "antd";
import React, { useState } from "react";

import Icon, { FolderOutlined, HomeOutlined,AppstoreOutlined,BulbOutlined ,FileTextOutlined,ImportOutlined,ProfileOutlined , LineChartOutlined, SettingOutlined, UserOutlined, StockOutlined, SlidersOutlined,FolderOpenOutlined,BlockOutlined,PlaySquareOutlined,QuestionCircleOutlined   } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import { ContractSVG, ExpenseSVG, HrSVG, InvoiceSVG, AggentLetterSVG, MatterSVG, BillSVG } from "./Menu";
import { PermissionType } from "../pages/Auth/PermissionType";
import { useSelector } from "react-redux";
import { classNames } from "@react-pdf-viewer/core";

function MenuSide({ onTitleChange }) {
  const location = useLocation();

  const translation = useTranslation();
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState("/");
  const authSelector = useSelector((state) => state.auth.auth);

  const permissionFuc = (arr) => {
    switch (authSelector.role) {
      case "application":
        return PermissionType.application.includes(arr.key);

      case "lab":
        return PermissionType.lab.includes(arr.key);

      case "note":
        return PermissionType.note.includes(arr.key);

      case "admin":
        return PermissionType.admin.includes(arr.key);

        case "Secretary":
          return PermissionType.Secretary.includes(arr.key);
  
    }
  };

  return (
    <Menu
      style={{ border: 0,justifyContent :'center',display:'flex',flexDirection:'column',height:'100%',padding:0,margin:0}}
      className="menu-side-test"
      selectedKeys={location.pathname.split("/")[1]}
      onClick={(e) => setSelectedMenuItem(e.key)}
      defaultSelectedKeys={["/"]}
      mode="inline"
      items={[
        
        {
          key: "Patient",
          icon: <UserOutlined />,
          label: translation.patient,
          className: "menu-item-test",
          onClick: () => {
            navigate("Patient");
            onTitleChange(translation.patient);
          },
        },
       
        {
          key: "HR",
          icon: <Icon component={HrSVG} />,
          label: "بەڕێوبردنی کارمەند",
          className: "menu-item-test",
          onClick: () => {
            navigate("HR");
            onTitleChange(translation.Report);
          },
        },

        {
          key: "Application",
          icon: <AppstoreOutlined />,
          className: "menu-item-test",
          label: "ئەپپڵیکەیشن",
          children: [
            {
              key: "Camparission",
              label: "کارەکان",
              icon: <FolderOpenOutlined  />,
              className: "menu-item-test",
              onClick: () => {
                navigate("Camparission");
                onTitleChange("کارەکان");
              },
            },
            {
              key: "Blog",
              label: "بڵۆگەکان",
              icon: <BlockOutlined />  ,
              className: "menu-item-test",
              onClick: () => {
                navigate("Blog");
                onTitleChange("بڵۆگەکان");
              },
            },

            {
              key: "FAQ",
              label: "پرسیارە باوەکان",
              icon: <QuestionCircleOutlined />  ,
              className: "menu-item-test",
              onClick: () => {
                navigate("FAQ");
                onTitleChange("پرسیارە باوەکان");
              },
            },

            {
              key: "InstructionVideos",
              label: "ڕێنمایی ڤیدیۆ",
              icon: <PlaySquareOutlined />  ,
              className: "menu-item-test",
              onClick: () => {
                navigate("InstructionVideos");
                onTitleChange("ڕێنمایی ڤیدیۆ");
              },
            },

            {
              key: "InstructionText",
              label: "ڕێنمایی بە نووسین",
              icon: <PlaySquareOutlined />  ,
              className: "menu-item-test",
              onClick: () => {
                navigate("InstructionText");
                onTitleChange("ڕێنمایی بە نووسین");
              },
            },
            

          ],

        },



        

        {
          key: "Note",
          icon: <FileTextOutlined />,
          label: translation.Note,
          className: "menu-item-test",
          onClick: () => {
            navigate("Note");
            onTitleChange("تێبینی");
          },
         
        },

        {
          key: "cliche",
          icon: <ImportOutlined />,
          label: translation.lab,
          className: "menu-item-test",
          onClick: () => {
            navigate("cliche");
            onTitleChange("کڵێشە");
          },
         
        },

        {
          key: "LabPage",
          icon: <ProfileOutlined />,
          label: "کڵێشە",
          className: "menu-item-test",
          onClick: () => {
            navigate("LabPage");
            onTitleChange(translation.lab);
          },
         
        },

        {
          key: "Tutorial",
          icon: <BulbOutlined />,
          label: "فێرکاری",
          className: "menu-item-test",
          onClick: () => {
            navigate("Tutorial");
            onTitleChange("فێرکاری");
          },
         
        },

        {
          key: "Settings",
          icon: <SettingOutlined />,
          label: translation.Settings,
          className: "menu-item-test",
          onClick: () => {
            navigate("Settings");
            onTitleChange(translation.Settings);
          },
        },
      ].filter(permissionFuc)}
    />
  );
}

export default MenuSide;
