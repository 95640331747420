import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { API_BASE_URL, HTTP_STATUS, PAGESIZEOPTION } from "../config/serverApiConfig";

// export const addClient = createAsyncThunk("ag/add", async (data) => {
//   const response = await axios
//     .post(`${API_BASE_URL}client/new`, data)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return response;
// });

export const getAg2 = createAsyncThunk("ag/get", async (data) => {
  const response = await axios
    .get(`${API_BASE_URL}ag?current=${data.current}&limit=${data.limit}&order=${data.order}&status=${data.status}&hr_ids=${data.hr_ids}&shwen_ids=${data.shwen_ids}&jor_ids=${data.jor_ids}&hr_id=${data.hr_id}`)
    .then((response) => {
        console.log(response.data)
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return {data:response,shwen_ids:data.shwen_ids,order:data.order,status:data.status,hr_ids:data.hr_ids,jor_ids:data.jor_ids};
});

// export const delClient = createAsyncThunk("ag/del", async (id) => {
//   const response = await axios
//     .delete(`${API_BASE_URL}client/delete?id=${id}`)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return id;
// });

// export const updateClient = createAsyncThunk("ag/update", async (fd) => {
 
//   const response = await axios
//     .put(`${API_BASE_URL}client/update?id=${fd.id}`,fd.fd)
//     .then((response) => {
      
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return {data:response,id:fd.id};
// });

const Ag = createSlice({
  name: "ag",
  initialState: {
    ag: [],

    pagination: {
      page: 1,
      pageSize: 15,
      total: 10,
    },
    table:{
      shwen_ids:"",
      status:"",
      hr_ids:"",
      order:"",
      jor_ids:""
    },
    status: null,
  },
  reducers: {

    deleteAg3am: (state, action) => {
      
      state.ag = state.ag.filter((ag) => ag.id !== parseInt(action.payload));
    }
    
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(addClient.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(addClient.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.client = [...state.client, action.payload];
    //   })
    //   .addCase(addClient.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
    builder
      .addCase(getAg2.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getAg2.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.ag = action.payload.data[0];
        state.pagination = {...action.payload.data[1][0],...PAGESIZEOPTION};
        state.table = {
          hr_ids:action.payload.hr_ids,
          status:action.payload.status,
          order:action.payload.order,
          shwen_ids:action.payload.shwen_ids,
          jor_ids:action.payload.jor_ids
        }
      })
      .addCase(getAg2.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    // builder
    //   .addCase(delClient.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(delClient.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.client = state.client.filter((item) => item.id !== action.payload);
    //   })
    //   .addCase(delClient.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
    // builder
    //   .addCase(updateClient.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(updateClient.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.client = state.client.map((item) => item.id === action.payload.id ? action.payload.data:item);

    //   })
    //   .addCase(updateClient.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
  },
});


export const { deleteAg3am } = Ag.actions;

export default Ag.reducer;
