import { Button, Card, Col, Collapse, Form, Input, Popconfirm, Row, Typography, message, theme,Image } from "antd";
import { CaretRightOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/serverApiConfig";

const { Title, Paragraph } = Typography;
function Faq() {
  const { useToken } = theme;
  const { token } = useToken();
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);

  const panelStyle = {
    marginBottom: 24,
    background: token.colorBgContainer,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const handleDelete = (i) => {
    axios
      .delete(`${API_BASE_URL}faq/${i.id}`)
      .then((res) => {
        console.log(res.data);
        message.success("پرسیارەکەت بەسەرکەوتویی سڕایەوە");
        setData(data.filter((item) => item.id !== i.id));
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە سڕینەوەی پرسیارەکان");
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}faq`)
      .then((res) => {
        setData(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <Row justify={"space-between"}>
          <Col>
            <Paragraph>This is panel header 1</Paragraph>
          </Col>
          <Col>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
            <Button type="link">
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      ),
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <Row justify={"space-between"}>
          <Col>
            <Paragraph>This is panel header 1</Paragraph>
          </Col>
          <Col>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
            <Button type="link">
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      ),
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <Row justify={"space-between"}>
          <Col>
            <Paragraph>This is panel header 1</Paragraph>
          </Col>
          <Col>
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
            <Button type="link">
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      ),
      children: <p>{text}</p>,
      style: panelStyle,
    },
  ];
  return (
    <div style={{ margin: 10, padding: 10 }}>
      <Row style={{ marginTop: 10, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding }} justify={"space-between"}>
        <Col>
          <Title level={4}>پرسیارە باوەکان</Title>
        </Col>

        <Col>
          <Button onClick={() => navigate(`/Faq/new`)} type="primary">
            زیادکردن
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }} justify={"start"}>
        <Col style={{ marginInline: 6 }} xs={24}>
          <Collapse
            bordered={false}
            // defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            style={{
              background: token.colorBgLayout,
            }}
            items={data.map((i) => {

              console.log(i)
              return {
                key: i.id,
                label: (
                  <Row justify={"space-between"}>
                    <Col>
                      <Paragraph>{i.titleKU}</Paragraph>
                  
                    </Col>
                    <Col>
                      <Popconfirm title="ئایا دڵنیایت لە سڕینەوەی پرسیارەکەت؟" okText="بەلێ" cancelText="نەخێر" onConfirm={() => handleDelete(i)}>
                        <Button type="link" danger>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Button onClick={() => navigate(`/FAQ/${i.id}`)} type="link">
                        <EditOutlined />
                      </Button>
                    </Col>
                  </Row>
                ),
                style: panelStyle,
                children: <>
                <p>{i.contentKU}</p>
                <a target="_blank" style={{display:'block'}} href={i.video}>Video</a>
                {i.image3 && <Image width={200}  src={i.image3} alt="faq" style={{display:'block'}}  />}
                {i.image && <Image width={200}  src={i.image} alt="faq" style={{display:'block'}}  />}
                {i.image2 && <Image width={200}  src={i.image2} alt="faq" style={{display:'block'}}  />}
                </>
             
              };
            })}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Faq;
