import { createAsyncThunk, createSlice, current, createAction } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { API_BASE_URL, HTTP_STATUS,PAGESIZEOPTION } from "../config/serverApiConfig";
import { useSelector } from "react-redux";

export const replaceWith = createAction("");

export const addMatterR = createAsyncThunk("matter/add", async (data) => {
  const response = await axios
    .post(`${API_BASE_URL}matter/new?hr_name=${data.id}`, data.data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const updateField = (state, { payload: { name, value } }) => {
  console.log(state);
  return {
    ...state,
    [name]: {
      ...state.data[name],
      value,
    },
  };
};

export const getMatter = createAsyncThunk("matter/get", async (data) => {
  
  const response = await axios
    .get(`${API_BASE_URL}matter?current=${data.current}&limit=${data.limit}&order=${data.order}&client_name=${data.client}&job=${data.job}&status_ids=${data.status}&hr_ids=${data.hr}&type=${data.category}&matter_id=${data.matter_id}&hr_id=${data.hr_id}`)
    .then((response) => {
      console.log(response.data)
      
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return { data: response, order: data.order, client: data.client, job: data.job, status: data.hr, hr: data.hr, category: data.category,matter_id:data.matter_id };
});

export const getMatterSolo = createAsyncThunk("matter/solo", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}matter/solo?id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const getCardBillable = createAsyncThunk("matter/card", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}matter/card/billable?matter_id=${id}`)
    .then((response) => {
      console.log(response.data, "hhhhhhhhhhhhh");
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const getCardUnpaid = createAsyncThunk("matter/unpaid", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}matter/card/invoice?matter_id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});
export const getCardCredit = createAsyncThunk("matter/credit", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}matter/card/refund?matter_id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

export const getCardSum = createAsyncThunk("matter/sum", async (id) => {
  const response = await axios
    .get(`${API_BASE_URL}matter/card/sum?matter_id=${id}`)
    .then((response) => {
      console.log("Sum", response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return response;
});

// export const delClient = createAsyncThunk("client/del", async (id) => {
//   const response = await axios
//     .delete(`${API_BASE_URL}client/delete?id=${id}`)
//     .then((response) => {
//       console.log(response.data);
//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return id;
// });

// export const updateClient = createAsyncThunk("client/update", async (fd) => {

//   const response = await axios
//     .put(`${API_BASE_URL}client/update?id=${fd.id}`,fd.fd)
//     .then((response) => {

//       return response.data;
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   return {data:response,id:fd.id};
// });

const MatterSlice = createSlice({
  name: "matter",
  initialState: {
    matter: [],
    soloMatter: {},
    billable: 0,
    unpaid: 0,
    credit: 0,
    sum: 0,
    lawyer_total: 0,
    gshty: 0,
    table: {
      order: "",
      matter_id:"",
      client: "",
      job: "",
      status: "",
      category: "",
      hr: "",
    },
    filterTableTitle: ["ئایدی", "ناوی کار", "خاوەنکار", "بەرواری کردنەوە", "بەرواری داخستن", "جۆر", "بار", "پارێزەر","کردار"],


    pagination: {
      page: 1,
      pageSize: 15,
      total: 10,
    },

    status: null,
  },
  reducers: {
    changeBillable: (state, action) => {
      state.billable = state.billable + action.payload;
    },
    updateExpenseCardM: (state, action) => {
      state.billable = state.billable + action.payload.QTY * action.payload.price;
    },

    deleteExpenseCardM: (state, action) => {
      state.billable = state.billable - action.payload.data;
    },

    update2ExpenseCardM: (state, action) => {
      state.billable = state.billable - action.payload.data;
    },

    updateNameColumnMatter: (state, action) => {
      
      if (action.payload.type === "add") {
        state.filterTableTitle = state.filterTableTitle.concat(action.payload.data);
      } else {
        state.filterTableTitle = state.filterTableTitle.filter((i) => i !== action.payload.data);
      }
    },
    
    deleteMatter: (state, action) => {
      
      state.matter = state.matter.filter((i) => i.id !== parseInt(action.payload));
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(addMatterR.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(addMatterR.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.client = [...state.matter, action.payload];
      })
      .addCase(addMatterR.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(getMatter.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })
      .addCase(getMatter.fulfilled, (state, action) => {
        console.log(action.payload);
        state.status = HTTP_STATUS.FULFILLED;
        state.matter = action.payload.data[0];
        state.pagination = {...action.payload.data[1][0],...PAGESIZEOPTION};
        state.table = {
          category: action.payload.category,
          hr: action.payload.hr,
          client: action.payload.client,
          job: action.payload.job,
          matter_id:action.payload.matter_id,
          order: action.payload.order,
          status: action.payload.status,
        };
      })
      .addCase(getMatter.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder
      .addCase(getMatterSolo.pending, (state) => {
        state.status = HTTP_STATUS.PENDING;
      })

      .addCase(getMatterSolo.fulfilled, (state, action) => {
        state.status = HTTP_STATUS.FULFILLED;
        state.soloMatter = action.payload;
      })
      .addCase(getMatterSolo.rejected, (state) => {
        state.status = HTTP_STATUS.REJECTED;
      });
    builder.addCase(getCardBillable.fulfilled, (state, action) => {
      state.billable = action.payload.total === null ? 0 : action.payload.total;
    });

    builder.addCase(getCardUnpaid.fulfilled, (state, action) => {
      state.unpaid = action.payload.total;
    });
    builder.addCase(getCardCredit.fulfilled, (state, action) => {
      state.credit = action.payload.total;
    });

    builder.addCase(getCardSum.fulfilled, (state, action) => {
      state.sum = action.payload.total;
      state.gshty = action.payload.gshty;
      state.lawyer_total = action.payload.lawyer_total;
    });

    // builder
    //   .addCase(delClient.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(delClient.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.client = state.client.filter((item) => item.id !== action.payload);
    //   })
    //   .addCase(delClient.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
    // builder
    //   .addCase(updateClient.pending, (state) => {
    //     state.status = HTTP_STATUS.PENDING;
    //   })
    //   .addCase(updateClient.fulfilled, (state, action) => {
    //     state.status = HTTP_STATUS.FULFILLED;
    //     state.client = state.client.map((item) => item.id === action.payload.id ? action.payload.data:item);

    //   })
    //   .addCase(updateClient.rejected, (state) => {
    //     state.status = HTTP_STATUS.REJECTED;
    //   });
  },
});

export const { changeBillable, updateExpenseCardM, deleteExpenseCardM,updateNameColumnMatter,deleteMatter } = MatterSlice.actions;

export default MatterSlice.reducer;
