import { Button, Col, Form, Input, Row, Typography, message, theme } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../config/serverApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { Upload } from "antd/lib";

const { Title, Paragraph } = Typography;

function NewFaq() {
  const { useToken } = theme;
  const { token } = useToken();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const finishForm = (value) => {
    setConfirmLoading(true);
    console.log(value);
    const formData = new FormData();

    if (value.image1 !== undefined && value.image1.length !== 0) {
      formData.append("image1", value.image1[0].originFileObj);
    }
    if (value.image2 !== undefined && value.image2.length !== 0) {
      formData.append("image2", value.image2[0].originFileObj);
    }

    if (value.image3 !== undefined && value.image3.length !== 0) {
      formData.append("image3", value.image3[0].originFileObj);
    }
    const { image1,image2,image3, ...rest } = value;
    formData.append("faq", JSON.stringify(rest));

    if (id) {
      axios
        .put(`${API_BASE_URL}faq/${id}`, formData)
        .then((res) => {
          console.log(res.data);
          message.success("پرسیارەکەت بەسەرکەوتویی گورانکاریکرا");
          setConfirmLoading(false);
          navigate(-1);
        })
        .catch((err) => {
          console.log(err);
          message.error("هەڵەیەک هەیە لە گورانکاریکردنی پرسیارەکان");
        });
    } else {
      axios
        .post(`${API_BASE_URL}faq`, formData)
        .then((res) => {
          message.success("پرسیارەکەت بەسەرکەوتویی زیادکرا");
          form.resetFields();
          setConfirmLoading(false);
        })
        .catch((err) => {
          console.log(err);
          message.error("هەڵەیەک هەیە لە زیادکردنی پرسیارەکان");
        });
    }
  };

  const getFile = (e) => {
    // if (Array.isArray(e)) {
    //   return e;
    // }
    return e && e.fileList;
  };

  console.log(fileList);

  useEffect(() => {
    if (id) {
      axios
        .get(`${API_BASE_URL}faq/${id}`)
        .then(async (res) => {
          form.setFieldsValue({
            titleKU: res.data.titleKU,
            titleAR: res.data.titleAR,
            titleEN: res.data.titleEN,
            contentKU: res.data.contentKU,
            contentAR: res.data.contentAR,
            contentEN: res.data.contentEN,
            video: res.data.video,
          });
        })
        .catch((err) => {
          console.log(err);
          message.error("هەڵەیەک هەیە لە گەڕاندنەوەی پرسیارەکان");
        });
    }
  }, []);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div style={{ margin: 10, padding: 10 }}>
      <Title style={{ color: "#E29578" }} level={3}>
        پرسیارە باوەکان
      </Title>
      <Form form={form} onFinish={finishForm} layout="vertical">
        <Row style={{ marginTop: 10, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding }}>
          <Col xs={6}>
            <Title style={{ color: "#006D77" }} level={4}>
              کوردی
            </Title>

            <Form.Item rules={[{ required: true, message: "" }]} name={"titleKU"} label="پرسیار">
              <Input />
            </Form.Item>

            <Form.Item rules={[{ required: true, message: "" }]} name={"contentKU"} label="ناوەڕۆک">
              <Input.TextArea />
            </Form.Item>

            <Title style={{ color: "#006D77" }} level={4}>
              عربی
            </Title>
            <Form.Item rules={[{ required: true, message: "" }]} name={"titleAR"} label="پرسیار">
              <Input />
            </Form.Item>

            <Form.Item rules={[{ required: true, message: "" }]} name={"contentAR"} label="ناوەڕۆک">
              <Input.TextArea />
            </Form.Item>

            <Title style={{ color: "#006D77" }} level={4}>
              ئینگلیزی
            </Title>
            <Form.Item rules={[{ required: true, message: "" }]} name={"titleEN"} label="پرسیار">
              <Input />
            </Form.Item>

            <Form.Item rules={[{ required: true, message: "" }]} name={"contentEN"} label="ناوەڕۆک">
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col style={{ marginInline: 8 }} xs={6}>
            <Title style={{ color: "#006D77" }} level={4}>
              کەرەستە
            </Title>
            <Form.Item name={"video"} label="لینکی ڤیدیۆ">
              <Input />
            </Form.Item>

            <Form.Item valuePropName="fileList" getValueFromEvent={getFile} style={{ marginBottom: "8px", marginTop: 0 }} label={""} labelCol={{ span: 5 }} name={"image1"}>
              <Upload listType="picture-card" fileList={fileList} onChange={(e) => setFileList(e)} maxCount={1} accept="image/*" customRequest={dummyRequest}>
                کرتە بکە دانان
              </Upload>
            </Form.Item>

            <Form.Item valuePropName="fileList" getValueFromEvent={getFile} style={{ marginBottom: "8px", marginTop: 0 }} label={""} labelCol={{ span: 5 }} name={"image2"}>
              <Upload listType="picture-card" fileList={fileList} onChange={(e) => setFileList(e)} maxCount={1} accept="image/*" customRequest={dummyRequest}>
                کرتە بکە دانان
              </Upload>
            </Form.Item>

            <Form.Item valuePropName="fileList" getValueFromEvent={getFile} style={{ marginBottom: "8px", marginTop: 0 }} label={""} labelCol={{ span: 5 }} name={"image3"}>
              <Upload listType="picture-card" fileList={fileList} onChange={(e) => setFileList(e)} maxCount={1} accept="image/*" customRequest={dummyRequest}>
                کرتە بکە دانان
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }} justify={"end"}>
          <Button loading={confirmLoading} htmlType="submit" type="primary">
            {id ? "گورانکاری" : "زیادکردن"}
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default NewFaq;
