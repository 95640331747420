import { Button, Checkbox, Col, DatePicker, Form, Grid, Input, Modal, Popover, Row, Segmented, Space, Table, Typography, message, theme } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { CheckSquareOutlined, EllipsisOutlined, PlusOutlined, PrinterOutlined, SearchOutlined, SettingOutlined, UndoOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import Highlighter from "react-highlight-words";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { API_BASE_URL } from "../config/serverApiConfig";
import useTranslation from "../customHooks/translations";
import { PatientPagination, incrementTotal, updateNameColumn } from "../slices/client.slice";
import { tableSize } from "../slices/util.slice";
import DefaultImage from "../styles/1.jpg";
import { render } from "@testing-library/react";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const Patient = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [printVisible, setPrintVisible] = useState(false);
  const componentRef = useRef(null);
  const [searchData, setSearchData] = useState([]);
  const [filterTableTitle, setFilterTableTitle] = useState();
  const translation = useTranslation();
  const authSelector = useSelector((state) => state.auth.auth);
  const themeSelector = useSelector((state) => state.util.theme);
  const [phoneNumber, setPhoneNumber] = useState("");
  const screens = useBreakpoint();

  const [patient, setPatient] = useState([]);

  const { useToken } = theme;
  const { token } = useToken();
  const [filterData, setFilterData] = useState({
    active: 0,
    noActive: 0,
  });
  const dispatch = useDispatch();
  const [count, setCount] = useState("");
  const [searchOn, setSearchOn] = useState(false);

  const [rangePickerM, setRangePickerM] = useState([]);
  const [baryDosya, setBaryDosya] = useState([]);

  const [cards, setCard] = useState({
    arshif: 0,
    active: 0,
  });
  const navigate = useNavigate();

  const clientRedux = useSelector((state) => state.clients);
  const size2 = useSelector((state) => state.util.size);
  const [loading, setLoading] = React.useState(false);

  console.log(clientRedux,"🤍🤍🤍🤍🤍");

  useEffect(() => {
    setLoading(true);
    async function tts() {
      axios.get(`${API_BASE_URL}patient?size=${clientRedux.pagination.pageSize}&page=${clientRedux.pagination.current-1}`).then((res) => {
       

        setPatient(res.data.content);

        dispatch(PatientPagination(res.data));

        setLoading(false);
      });

      axios.get(`${API_BASE_URL}patient/cards`).then((res) => {
        console.log(res.data);

        setCard({
          arshif: res.data.archive,
          active: res.data.active,
        });
      });
    }

    tts();
  }, []);

  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch2 = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  console.log(themeSelector);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch2(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch2(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    // onFilter: (value, record) => record.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handelRefresh = async () => {
    setLoading(true);

    axios.get(`${API_BASE_URL}patient?size=${clientRedux.pagination.pageSize}&sort=${clientRedux.order}&page=${clientRedux.pagination.page}`).then((res) => {
      setPatient(res.data.content);

      dispatch(PatientPagination(res.data));

      setLoading(false);
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setPrintVisible(true),
    onBeforeGetContent: () => handleOnBeforeGetContent(),
    onAfterPrint: () => setPrintVisible(false),
  });

  const handelDataTableLoad = useCallback(async (pagination, filter, sorter, extra) => {
    setLoading(true);
    let sort = sorter.order !== undefined ? (sorter.order === "ascend" ? "ASC" : "DESC") : "DESC";
    axios.get(`${API_BASE_URL}patient?size=${pagination.pageSize}&sort=${sort}&page=${pagination.current - 1}`).then((res) => {
      setPatient(res.data.content);

      dispatch(PatientPagination({ ...res.data, sort }));

      setLoading(false);
    });

    setLoading(false);
  }, []);

  function createRandomUser() {
    return [
      {
        // date: faker.date.past(),
      },
    ];
  }

  const columns = [
    Table.SELECTION_COLUMN,
    {
      title: translation.id,
      dataIndex: "id",
      sorter: true,
      ...getColumnSearchProps("ئایدی"),
      key: "id",
      width: "10%",
      render: (item) => {
        return <p style={{ margin: "auto 0" }}>ID-00{item}</p>;
      },
    },

    {
      dataIndex: "image",
      title: "وێنە",
      width: "6%",
      render: (item, rec) => {
        return (
          <div key={nanoid(3)} style={{ width: 25, height: 25, margin: "0 5px" }}>
            <img src={item ? item : DefaultImage} style={{ width: "100%", borderRadius: 14, height: "100%", display: "inline", margin: "0 8px", objectFit: "cover" }} />
          </div>
        );
      },
      key: "8",
    },

    {
      title: "ناوی سیانی",
      dataIndex: "fullName",
      ...getColumnSearchProps("ناوی سیانی"),
      // sorter: true,
      key: "f_name",
      render  : (item) => { 
        return <p style={{ margin: "auto 0",color:"#B22A00" }}>{item}</p>;

      }
    },


    {
      title: "تەمەن",
      dataIndex: "age",
      key: "6",
    },
    {
      title: translation.Phone,
      dataIndex: "phoneNumber",
      key: "6",
      render: (item) => {
        // return p without + i mean 964

        return <p style={{ margin: "auto 0",color:"#CF7900" }}>{item.slice(1)}+</p>;
      },
    },

   

    {
      title: "بەرواری دروست کردن",
      dataIndex: "date",
      key: "date",
      render: (item) => {
        return <p style={{ margin: "auto 0" }}>{dayjs(item).format("YYYY/MM/DD")}</p>;
      },
    },

    {
      title: "کردار",
      dataIndex: "navigate",
      key: "90",
      render: (item, record) => {
        return (
          <Button onClick={() => navigate(`/Patient/${record.id}`)} type="link">
            کردنەوە
          </Button>
        );
      },
    },
  ];

  const onSelectChange = (selectedRowKeys2, data) => {
    if (selectedRowKeys2.length > 1) {
      const lastSelectedRowIndex = [...selectedRowKeys2].pop();
      setSelectedRowKeys(lastSelectedRowIndex);
    }
    setPrintData(data);
    setSelectedRowKeys(selectedRowKeys2);
  };

  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      setPrintVisible(true);

      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (e) => {
    if (searchText.trim().length === 0 && rangePickerM.length === 0) {
      setSearchOn(false);
      return message.warning("ببورە وشەیەک بنوسە بۆ گەڕان");
    }
    setLoading(true);
    setSearchOn(true);
    axios.post(`${API_BASE_URL}patient/search`, { searchKey: searchText }).then((res) => {
      console.log(res.data);
      setSearchData(res.data);
      setLoading(false);
    });
  };

  const checkFilter = (e) => {
    return clientRedux.filterTableTitle.includes(e.title);
  };

  const handleColumnC = (e, check) => {
    if (check) {
      let temp = clientRedux.filterTableTitle.concat(e.title);
      dispatch(updateNameColumn({ data: e.title, type: "add" }));
    } else {
      let temp = clientRedux.filterTableTitle.filter((i) => i !== e.title);
      dispatch(updateNameColumn({ data: e.title, type: "remove" }));
    }
  };

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [patientForm] = Form.useForm();
  const submitPatient = (values) => {
    console.log(values);
    setConfirmLoading(true);

    let phoneNumberWithoutSpace = values.phoneNumber.replace(/\s/g, "");

    axios
      .post(`${API_BASE_URL}patient`, { ...values, phoneNumber: phoneNumberWithoutSpace })
      .then((res) => {
        message.success("بە سەرکەوتوویی تۆمارکرا");
        setVisible(false);
        setConfirmLoading(false);
        patientForm.resetFields();
        dispatch(incrementTotal());
        console.log(res.data);
        setPatient((prev) => [res.data, ...prev]);
      })
      .catch((err) => {
        if (err.response.status === 515) {
          setConfirmLoading(false);
          return message.error("ئەم ژمارەیە بەکارهێنراوە لە لیستەکە");
        }
        console.log(err);
        message.error("هەڵەیەک هەیە لە زیادکردن");
        setConfirmLoading(false);
      });
  };

  return (
    <div className="slb2">
      <Row justify={"center"} className="com">
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row style={{ marginBottom: 15 }} justify={screens.xl ? "start" : "center"}>
            <Col style={{ marginInline: screens.xl ? 0 : 6, marginTop: screens.lg ? 0 : 6, marginInline: screens.xl ? 10 : 0 }} xs={23} xl={4} xxl={4} sm={23} md={23} lg={4}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", backgroundColor: "#0E4045", borderRadius: token.borderRadius, padding: token.padding }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Title style={{ color: "#fff", marginBlock: 4 }} level={5}>
                    ژمارەی چارەخواز
                  </Title>
                  <Title style={{ color: "#fff", marginBlock: 4 }} level={5}>
                    {" "}
                    چارەخواز {clientRedux.pagination.total}
                  </Title>
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 40, height: 40, backgroundColor: "rgba(255,255,255,.6)", borderRadius: "50%" }}>
                  <svg width="22" height="24" viewBox="0 0 639 672" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M308.017 3.59347L498.697 113.681L292.271 320.121L201.953 229.804C182.724 210.58 154.703 203.069 128.437 210.107C102.172 217.143 81.656 237.659 74.62 263.919C67.5836 290.184 75.0887 318.205 94.3173 337.435L238.464 481.581C252.735 495.847 272.089 503.863 292.271 503.863C312.453 503.863 331.812 495.847 346.083 481.576L589.163 238.483V490.549C589.163 495.273 587.923 499.909 585.564 503.997C583.204 508.086 579.809 511.482 575.72 513.841L308.027 668.388C303.938 670.747 299.303 671.992 294.584 671.992C289.86 671.992 285.225 670.747 281.136 668.388L13.4427 513.841C9.35933 511.482 5.96347 508.086 3.60414 503.997C1.2396 499.909 0 495.273 0 490.549V181.443C0 171.833 5.12507 162.953 13.4427 158.151L281.136 3.60413C285.225 1.2448 289.86 0 294.584 0C299.303 0 303.938 1.24479 308.027 3.60413L308.017 3.59347ZM178.023 253.713C167.34 243.031 151.772 238.859 137.184 242.771C122.591 246.682 111.195 258.077 107.283 272.667C103.376 287.26 107.548 302.828 118.225 313.511L262.372 457.644C270.299 465.571 281.055 470.024 292.268 470.024C303.481 470.024 314.237 465.571 322.164 457.639L626.231 153.572C636.897 142.89 641.059 127.327 637.147 112.744C633.236 98.1603 621.845 86.7709 607.262 82.8589C592.679 78.9469 577.117 83.1089 566.434 93.7808L292.261 367.954L178.023 253.713Z" fill="#fff" />
                  </svg>
                </div>
              </div>
            </Col>

            <Col style={{ marginInline: screens.xl ? 0 : 6, marginTop: screens.lg ? 0 : 6, marginInline: screens.xl ? 10 : 0 }} xs={23} xl={4} xxl={4} sm={23} md={23} lg={4}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", backgroundColor: "#006E78", borderRadius: token.borderRadius, padding: token.padding }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Title style={{ color: "#fff", marginBlock: 4 }} level={5}>
                    چارەخوازی چالاک
                  </Title>
                  <Title style={{ color: "#fff", marginBlock: 4 }} level={5}>
                    {" "}
                    {cards.active}
                  </Title>
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 40, height: 40, backgroundColor: "rgba(255,255,255,.6)", borderRadius: "50%" }}>
                  <svg width="18" height="18" viewBox="0 0 665 665" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M507.72 332.265C507.72 429.167 429.163 507.719 332.267 507.719C235.371 507.719 156.813 429.161 156.813 332.265C156.813 235.369 235.371 156.812 332.267 156.812C429.163 156.812 507.72 235.369 507.72 332.265Z" fill="#444" />
                    <path d="M332.267 0C148.773 0 0 148.76 0 332.267C0 515.773 148.76 664.533 332.267 664.533C515.773 664.533 664.533 515.773 664.533 332.267C664.533 148.773 515.773 0 332.267 0ZM332.267 582.4C194.12 582.4 82.1333 470.405 82.1333 332.267C82.1333 194.128 194.128 82.1333 332.267 82.1333C470.405 82.1333 582.4 194.128 582.4 332.267C582.4 470.405 470.405 582.4 332.267 582.4Z" fill="#444" />
                  </svg>
                </div>
              </div>
            </Col>

            <Col style={{ marginInline: screens.xl ? 0 : 6, marginTop: screens.xl ? 0 : 6, marginInline: screens.xl ? 10 : 0 }} xs={23} xl={4} xxl={4} sm={23} md={23} lg={4}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", backgroundColor: "#781D00", borderRadius: token.borderRadius, padding: token.padding }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Title style={{ color: "#fff", marginBlock: 4 }} level={5}>
                    چارەخوازی بلاک لیست
                  </Title>

                  <Title style={{ color: "#fff", marginBlock: 4 }} level={5}>
                    {cards.arshif}
                  </Title>
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 40, height: 40, backgroundColor: "rgba(255,255,255,.6)", borderRadius: "50%" }}>

                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 8.50001L8.5 13.5M8.5 8.50001L13.5 13.5M6 2.33801C7.51945 1.45874 9.24448 0.997119 11 1.00001C16.523 1.00001 21 5.47701 21 11C21 16.523 16.523 21 11 21C5.477 21 1 16.523 1 11C1 9.17901 1.487 7.47001 2.338 6.00001" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
</svg>


</div>


              </div>
            </Col>
          </Row>

          <Row style={{ backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, boxShadow: token.boxShadow, padding: token.padding }} justify={"space-between"}>
            <Col xs={24}>
              <Title id="prime-color" level={4}>
                چارەخوازەکان
              </Title>
            </Col>
            <Col>
              <Space>
                <Input
                  suffix={<SearchOutlined className="site-form-item-icon " />}
                  size="middle"
                  onKeyDown={(e) => e.key === "Enter" && handleSearch()}
                  onChange={(e) => {
                    if (e.target.value.trim().length === 0) {
                      setSearchOn(false);
                    }
                    setSearchText(e.target.value);
                  }}
                  bordered={false}
                  allowClear
                  style={{ borderBottom: `1px solid ${token.colorText}` }}
                  placeholder={translation.Search}
                />

                <Button onClick={handleSearch} type="primary" style={{ borderRadius: 20 }}>
                  گەڕان
                </Button>
              </Space>
            </Col>

            <Col>
              {!screens.xl ? (
                <Popover
                  placement="right"
                  overlayStyle={{ width: "50%" }}
                  content={
                    <>
                      <Popover
                        placement="bottom"
                        content={columns
                          .slice(1)
                          //   .filter((i) => i.key !== "90" && i.key !== "8")
                          .map((i) => {
                            return (
                              <div style={{ display: "flex" }}>
                                <Space style={{ marginBlock: 3 }}>
                                  <Checkbox defaultChecked onChange={(e) => handleColumnC(i, e.target.checked)} />
                                  <p>{i.title}</p>
                                </Space>
                              </div>
                            );
                          })}
                        trigger={"click"}
                      >
                        <Button type="text">
                          <CheckSquareOutlined />
                        </Button>
                      </Popover>
                      <Popover content={<Segmented value={size2} onChange={(e) => dispatch(tableSize(e))} options={["small", "middle", "large"]} />} trigger={"click"}>
                        <Button type="text">
                          <SettingOutlined />
                        </Button>
                      </Popover>
                      <Button size="middle" onClick={handlePrint} type="text">
                        <PrinterOutlined />
                      </Button>
                      <Button size="middle" onClick={handelRefresh} type="text">
                        <UndoOutlined />{" "}
                      </Button>
                      {(authSelector.role === "admin" || authSelector.role === "Secretary") && (
                        <Button size="middle" style={{ borderRadius: 20 }} onClick={() => setVisible(true)} type="primary">
                          <PlusOutlined /> نوێ
                        </Button>
                      )}
                    </>
                  }
                >
                  <Button type="ghost">
                    <EllipsisOutlined />
                  </Button>
                </Popover>
              ) : (
                <Space size={5}>
                  <Popover
                    placement="bottom"
                    content={columns
                      .slice(1)
                      //   .filter((i) => i.key !== "90" && i.key !== "8")
                      .map((i) => {
                        return (
                          <div style={{ display: "flex" }}>
                            <Space style={{ marginBlock: 3 }}>
                              <Checkbox defaultChecked onChange={(e) => handleColumnC(i, e.target.checked)} />
                              <p>{i.title}</p>
                            </Space>
                          </div>
                        );
                      })}
                    trigger={"click"}
                  >
                    <Button type="text">
                      <CheckSquareOutlined />
                    </Button>
                  </Popover>
                  <Popover content={<Segmented value={size2} onChange={(e) => dispatch(tableSize(e))} options={["small", "middle", "large"]} />} trigger={"click"}>
                    <Button type="text">
                      <SettingOutlined />
                    </Button>
                  </Popover>
                  <Button size="middle" onClick={handlePrint} type="text">
                    <PrinterOutlined />
                  </Button>
                  <Button size="middle" onClick={handelRefresh} type="text">
                    <UndoOutlined />{" "}
                  </Button>
                  {(authSelector.role === "admin" || authSelector.role === "Secretary") && (
                    <Button size="middle" style={{ borderRadius: 20 }} onClick={() => setVisible(true)} type="primary">
                      <PlusOutlined /> نوێ
                    </Button>
                  )}
                </Space>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 10, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, boxShadow: token.boxShadow, padding: token.padding }} justify={"space-between"}>
            <Col xs={24}>
              <Table
                size={size2}
                style={{ marginTop: 10 }}
                rowClassName={(record, index) => (index % 2 === 0 ? (themeSelector === "dark" ? "table-row-dark2" : "table-row-dark") : themeSelector === "dark" ? "table-row-light2" : "table-row-light")}
                title={() => (
                  <Row justify="space-between">
                    <Col></Col>
                    <Col></Col>
                  </Row>
                )}
                scroll={{
                  x: 1000,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      navigate(`/Patient/${record.id}`);
                    }, 
                  };
                }}
                ref={componentRef}
                pagination={searchOn || printVisible ? false : clientRedux.pagination}
                columns={columns.filter(checkFilter)}
                loading={loading}
                dataSource={searchOn ? searchData : patient}
                rowKey={(item) => item.id}
                onChange={handelDataTableLoad}
                rowSelection={rowSelection}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        onOk={() => patientForm.submit()}
        open={visible}
        centered
        okText="زیادکردن"
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی چارەخواز
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitPatient(values);
            }
          }}
        >
          <Form name="AWS" form={patientForm} layout="horizontal">
            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"ناوی سیانی"} labelCol={{ span: 5 }} name={"fullName"}>
              <Input placeholder={"ناوی سیانی"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label="ژم. مۆبایل" labelCol={{ span: 5 }} name={"phoneNumber"}>
              <div style={{ direction: "ltr" }}>
                <PhoneInput country={"iq"} value={phoneNumber} onChange={(phone) => setPhoneNumber(phone)} placeholder="ژم. مۆبایل" />
              </div>
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"تەمەن"} labelCol={{ span: 5 }} name={"age"}>
              <Input placeholder={"تەمەن"} />
            </Form.Item>

            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"بەروار"} labelCol={{ span: 5 }} name={"date"}>
              <DatePicker inputReadOnly placeholder={"بەروار"} />
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
};

export default Patient;
