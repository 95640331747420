import React, { useEffect, useState } from "react";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import { Row, Col, theme, Typography, Space,Card, List } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_BASE_URL } from "../config/serverApiConfig";
import { Badge } from "antd/lib";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [stompClient, setStompClient] = useState(null);
  const navigate = useNavigate();

  const { useToken } = theme;
  const { token } = useToken();

  let objectPrinter = (object) => {
    let count = 0;
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
       if(object[key] === true){
        count = count + 1;
       }
        
      }
    }
    return count;
  };

  const data = [
    {
      title: 'Title 1',
    },
    {
      title: 'Title 2',
    },
    {
      title: 'Title 3',
    },
    {
      title: 'Title 4',
    },
  ];

  useEffect(() => {
    const socket = new SockJS(`${API_BASE_URL}socket`);
    const stomp = Stomp.over(socket);
    stomp.connect({}, () => {
      setStompClient(stomp);
      stomp.subscribe("/topic/messages", (message) => {
        const newMessage = JSON.parse(message.body);
        setMessages((prev) => [...prev, newMessage]);
        console.log(messages, newMessage, "✔✔✔✔✔✔✔");
      });
    });

    axios.get(`${API_BASE_URL}uncheck/res`).then((res) => {
        setMessages(res.data);
    });


  }, []);

 

  console.log(messages, "✔✔✔✔✔✔✔");

  return (
    <div>
      <Row style={{ margin: 20, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, boxShadow: token.boxShadowTertiary, padding: token.padding }}>
        <Col xs={24}>
          <Space>

            <Title style={{ display: "inline" }} level={4}>
                    پشکنینەکان
            </Title>

            <SyncOutlined style={{ fontSize: 16, color: token.colorPrimary }} spin={180} />

          </Space>
        </Col>
      </Row>

      <Row style={{ margin: 20 }}>
        <Col xs={24}>




      <List
   className="grid lg:grid-cols-4 md:grid-cols-3 xs:grid-cols-1"
    dataSource={messages}
    renderItem={(item) => {

        const {patient,discount,date,tested,id,...rest } = item;
        console.log(rest)

        let count =  objectPrinter(rest);

        console.log(count)

   

        return(

       
      <List.Item className=" ">
        <Card className="w-full" onClick={() => navigate(`/Cliche/${item.id}`)}  title={<Row justify={"space-between"}> 
            <Col  >
                <Title level={5}>{item.patient.fullName}</Title>
            </Col>
            <Col>
            <Badge size={20} status="processing" dot>

            </Badge>
            </Col>
        </Row>}>ژمارەی پشکنین : {count} </Card>
      </List.Item>
     )}}
  />

  </Col>

  </Row>

      {/* <Row style={{ margin: 20, backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, boxShadow: token.boxShadowTertiary, padding: token.padding }} justify={"space-between"}>
        <Col ></Col>
 
</Row> */}

    </div>
  );
};

export default ChatComponent;
