import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Modal, Popconfirm, Row, Select, Typography, Upload, message, theme } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../config/serverApiConfig";

const { Title, Paragraph } = Typography;
const { Option } = Select;

function LabResult() {
  const { id } = useParams();


  const { useToken } = theme;
  const { token } = useToken();
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState([]);
  const [rec, setRec] = useState({});
  const handleDelete = (rec) => {
    axios
      .delete(`${API_BASE_URL}lab/${rec.id}`)
      .then((res) => {
        setData(data.filter((item) => item.id !== rec.id));
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە سڕینەوەی تێبینی");
      });
  };

  useEffect(() => {
    axios.get(`${API_BASE_URL}lab/${id}`).then((res) => {
      
      setData(res.data);
    });
  }, []);

  const [NoteForm] = Form.useForm();
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const submitWork = (value) => {
    setConfirmLoading(true);
    let formData = new FormData();

    console.log(value);
    if (value.upload !== undefined && value.upload.fileList.length !== 0) {
      formData.append("image", value.upload.fileList[0].originFileObj);
    }

    axios
      .post(`${API_BASE_URL}lab?p_id=${id}`, formData)
      .then((res) => {
        setData([...data, res.data]);
        setVisible(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("هەڵەیەک هەیە لە زیادکردنی تێبینی");
      });
  };

  return (
    <div>
      <Row justify="space-around" style={{ marginTop: 0, borderRadius: 6, padding: 10, backgroundColor: token.colorBgContainer }}>
        <Col xs={24}>
          <Row justify={"space-between"}>
            <Title level={4}>ئەنجامی تاقیگە</Title>
            <Button onClick={() => setVisible(true)} type="primary">
              زیادکردن <PlusOutlined />{" "}
            </Button>
          </Row>
        </Col>
      </Row>


       {data.length > 0 &&
        data.map((item) => (
          <Row style={{ marginTop: 20 }}>
            <Col style={{ marginTop: 0, borderRadius: 6, padding: 10, backgroundColor: token.colorBgContainer, display: "flex" }} xs={24}>
              <Row style={{ width: "100%" }} justify={"space-between"}>
                <div>
                  <Image width={100} src={item.image} />
                  
                </div>
                <div>
                  <Popconfirm onConfirm={() => handleDelete(item)} title="ئایا دڵنیایت لە سڕینەوەی تێبینی؟" okText="بەڵێ" cancelText="نەخێر">
                    <Button type="link" danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              </Row>
            </Col>
          </Row>
        ))}

      <Modal
        onOk={() => NoteForm.submit()}
        open={visible}
        centered
        okText={update ? "نوێکردنەوە" : "زیادکردن"}
        cancelText="پاشگەزبوونەرە"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          setUpdate(false);
        }}
        title={
          <Title style={{ color: "#59114d" }} level={5}>
            زیاد کردنی ئەنجامی تاقیگە
          </Title>
        }
      >
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "AWS") {
              submitWork(values);
            }
          }}
        >
          <Form name="AWS" form={NoteForm} layout="horizontal">
            <Form.Item style={{ marginBottom: "8px", marginTop: 0 }} label={"تێبینی"} labelCol={{ span: 5 }} name={"upload"}>
              <Upload listType="picture-card" maxCount={1} accept="image/*" customRequest={dummyRequest}>
                Click to Upload
              </Upload>
            </Form.Item>
          </Form>
        </Form.Provider>
      </Modal>
    </div>
  );
}

export default LabResult;
