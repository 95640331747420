  export const API_BASE_URL =  "https://drkarwanbackend-production.up.railway.app/"
  // export const API_BASE_URL =  "http://192.168.1.53:8080/"
  
 export const PAGESIZEOPTION =  {pageSizeOptions:[10,15,20,50,100,500,1000,2000]}
 

export const GLOBALIQD = 1470;

//export const API_BASE_URL = "http://localhost:4000/"
//export const API_BASE_URL = "https://arf-law-backend.onrender.com/"
//export const API_BASE_URL = "https://arflaw-backend.vercel.app/"

// export const API_BASE_URL = "https://starter-mern.herokuapp.com/api/";
export const ACCESS_TOKEN_NAME = "x-auth-token";

export const HTTP_STATUS = Object.freeze({
  PENDING: "PENDING",
  FULFILLED: "FULFILLED",
  REJECTED: "REJECTED",
});
