import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Viewer } from '@react-pdf-viewer/core';
import { Grid } from 'antd'
import { TextDirection } from '@react-pdf-viewer/core';

const { useBreakpoint } = Grid;


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Quixote({ blob }) {
  const [pdfString, setPdfString] = useState(false);
  const [numPages, setNumPages] = useState(null);

  const screens = useBreakpoint();

  const getBlob = async ()  => {
    try {
        let unit8array = await fetch(URL.createObjectURL(blob)).then(res => res.arrayBuffer())
        .then(buffer => {
         
            return new Uint8Array(buffer);
        });
       setPdfString(unit8array);
        

      } catch (e) {
        console.log(e, "error");
      }
    };

  useEffect(() => {
    if (blob) {
     getBlob()
    }
  }, [blob]);

  return (
   <>

{
    pdfString && 
    <div style={{width:!screens.sm ?"95vw":"50vw",margin:'auto'}}>
   
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
    <Viewer  fileUrl={pdfString} />
 
 </Worker>
      
 </div>
}
   
</>
 
  )
}

export default Quixote;