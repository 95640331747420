export default {
  login:"Log In",
  email:"Email",
  password: "Password",
  name: "Name",
  RememberMe: "Remember me",
  HelloAgain: "Hello again!",
  softwareName: "DR. Karwan Clinic ",
  InputEmail: 'Please input your Email!',
  InputPass: "Please input your Password!",
  InputFlatRate: "Please input your Flat Rate!",
  InputCurrency: "Please input your Flat Currency!",
  InputSubject: "Please input your subject!",
  InputStart: "Please input your start!",
  InputExpense: "Please select expense!",
  InputAmount: "Please input your amount!",
  TodaySum: "Today Summary",
  today: "Today",
  Matter: "Matter",
  Expense: "Expense",
  Activity: "Activity",
  Invoice: "Invoice",
  Dashboard: "Dashboard",
  HrManagement: "Hr Management",
  Report: "Report",
  Contract: "Contract",
  Contact: "Contact",
  Language: "Language",
  Logout: "Logout",
  Settings: "Settings",
  Deposit: "Deposit Money",
  DueBalance: "Due Balance",
  QuickCreate: "Quick Create.",
  DailyUpdate: "Daily Update",
  Exchange: "Exchange",
  Update: "Update",
  Calculator: "Calculator",
  NoNotification: "No Notification",
  New:"New",
  RecentContact:"Recent Contact",
  Name:"Name",
  Age:"Age",
  Phone:"Phone",
  FirstName:"First Name",
  LastNAme:"Last Name",
  Home:"Home",
  Note:"Note",
  Refresh:"Refresh",
  AddNew:"Add New",
  Search:"Search",
  list:"list",
  Paid:"Paid",
  Due:"Due",
  Billable:"Billable",
  CreatedAt:"Created At",
  Info:"Info",
  Delete:"Delete",
  Cancel:"Cancel",
  Back:"Back",
  Doyouwantdelete:"Do you want delete",
  DoyouwantSave:"Do you want Save",
  Start:"Start",
  End:"End",
  FlatRate:"Flat Rate",
  Currency:"Currency",
  IQD:"IQD",
  DOLLAR:"DOLLAR",
  Completed:"Completed",
  NotCompleted:"Not Completed",
  Input:"Please Input",
  Subject:"Subject",
  Status:"Status",
  Add:"Add",
  Date:"Date",
  Description:"Description",
  Action:"Action",
  Save:"Save",
  Edit:"Edit",
  SureTo:"Sure to " ,
  Amount:"Amount" ,
  Receipt:"Receipt" ,
  Preview:"Preview" ,
  InvoiceDate:"Invoice Date" ,
  Sub:"Sub" ,
  PaymentDue:"Payment Due" ,
  StillNotPaid:"Still Not Paid" ,
  AddNewInvoice:"Add New Invoice" ,
  AddNewMatter:"Add New Matter" ,
  AddNewLawyer:"Add new Lawyer" ,
  AddNewContact:"Add New Contact" ,
  AddNewExpense:"Add New Expense" ,
  GenerateNewInvoice:"Generate New Invoice",
  Lawyers:"Lawyers",
  Profile:"Profile",
  Appearance:"Appearance",
  Notification:"Notification",
  Agenda:"Agenda",
  Create:"Create",
  About:"About",
  Qty:"Qty",
  NewLawyer:"New Lawyer",
  BilledBy:"Billed by",
  LinkedTo:"Linked to",
  ClickToUpload:"Click to Upload",
  GenerateInvoice:"Generate Invoice",
  GeneratedFor:"Generated for",
  SelectLawyer:"Select Lawyer",
  FlatFeeWinCase:"Flat Fee win case",
  FlatFeeLoseCase:"Flat Fee lose case",
  Pending:"Pending",
  Print:"Print",
  patient:"Patient",
  lab:"Laboratory",
  SubTotal:"Sub Total:",
  SaveInvoice:"Save Invoice",
  MarkAsPaid:"Mark as paid",
  NewActivity:"New Activity",
  NewInvoice:"New Invoice",
  NewMatter:"New Matter",
  NewExpense:"New Expense",
  CreateANewMatter:"Create a new Matter",
  ListContact:"Contact List",
  InfoMatter:"Matter Info",
  ExpenseList:"Expense List",
  ContactList:"Client List",
  MatterList:"Matter List",
  hrList:"All HR",
  id:"ID",
  InvolveLawyer:"Involve Lawyer",







};
