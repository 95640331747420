import { Button, Col, Image, Row, Typography, theme } from "antd";
import React, { useEffect, useState } from "react";
import LightTheme from "../../styles/LightTheme.png";
import DarkTheme from "../../styles/DarkTheme.png";
import AutoTheme from "../../styles/AutoTheme.png";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "../../slices/util.slice";






function Appearance() {
  const { useToken } = theme;
  const { token } = useToken();

  const { Paragraph, Title } = Typography;

  const selector = useSelector((state) => state.util);
  const dispatch = useDispatch();

 

 


  const changeTheme = (theme22) => {



    if(theme22==="light"){
      dispatch(updateTheme("light"));
    }
    else if(theme22==="dark"){
      dispatch(updateTheme("dark"));
    }
    else{
      dispatch(updateTheme("auto"));
    }

   

  





  }

  return (
    <div>
      <Row>
        <Col style={{ backgroundColor: token.colorBgContainer, borderRadius: token.borderRadius, padding: token.padding }} span={24}>
          <Title level={3}>ڕووکار</Title>

          {/* Three Image Button with Focus have Primary border color  */}
          <Row>
            <Col style={{marginInline:10}} >
              <Image style={{outline:selector.theme === "light"?`4px solid #009AF1 `:"none",borderRadius:token.borderRadius}} onClick={() => changeTheme("light")} preview={false} width={150} src={LightTheme} />
              <Title level={5}>ڕووکاری ڕۆشن</Title>
            </Col>

            <Col style={{marginInline:10}}>
              <Image  style={{outline:selector.theme === "dark"?`4px solid #009AF1 `:"none",borderRadius:token.borderRadius}}  onClick={() => changeTheme("dark")} preview={false} width={150} src={DarkTheme} />
              <Title level={5}>ڕووکاری تاریک</Title>
            </Col>

            <Col style={{marginInline:10}}>
              <Image  style={{borderRadius:token.borderRadius}} onClick={() => changeTheme("auto")} preview={false} width={150} src={AutoTheme} />
              <Title level={5}>ئۆتۆ</Title>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Appearance;
