import { Button, Col, Form, Image, Input, InputNumber, Modal, QRCode, Row, Select, Space, Tabs, Tag, Typography, Upload, message, theme } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined, PrinterOutlined,EyeInvisibleOutlined,EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../config/serverApiConfig";
import useTranslation from "../../customHooks/translations";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import Default from "../../styles/1.jpg";
import BeforeAfter from "./BeforeAfter";
import Dawakari from "./Dawakari";
import LabResult from "./LabResult";
import NotesPatient from "./NotesPatient";
import Visit from "./Visit";

const { Title, Paragraph } = Typography;

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function PatientDetail() {
  const { id } = useParams();
  const [formeUpdate] = Form.useForm();
  const translation = useTranslation();
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [updateVisible, setUpdateVisible] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [optionJor, setOptionJor] = React.useState([]);
  const [visablePassword, setVisablePassword] = React.useState(false);
  const [printVisible, setPrintVisible] = useState(false);
  const componentRef = useRef(null);
  const authSelector = useSelector((state) => state.auth.auth);

  const billable = useSelector((state) => state.clients);
  const { clientSolo } = billable;
  const [modalText, setModalText] = React.useState("Remove Contact");
  const navigate = useNavigate();

  console.log(clientSolo, "clientSolo");

  const dispatch = useDispatch();
  const [client, setClient] = useState({
    url: "",
  });
  const [tab, setTab] = useState("Contact");
  const { useToken } = theme;
  const { token } = useToken();

  const [imageUrl, setImageUrl] = useState("");

  const { TabPane } = Tabs;

  useEffect(() => {
    axios.get(`${API_BASE_URL}history/count/${id}`).then((res) => {
      setCount(res.data);
    });

    axios.get(`${API_BASE_URL}patient/${id}`).then((res) => {
      formeUpdate.setFieldsValue({
        patient: {
          fullName: res.data.fullName,
          age: res.data.age,
          phoneNumber: res.data.phoneNumber,
          status: res.data.status === "true" ? true : false,
          date: res.data.date,
        },
      });

      setClient({
        ...res.data,
      });
    });
  }, []);

  function callback(key) {
    setTab(key);
  }

  const handleOnBeforeGetContent = React.useCallback(() => {
    return new Promise((resolve) => {
      setPrintVisible(true);

      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => handleOnBeforeGetContent(),
    onBeforeGetContent: () => setPrintVisible(true),
    onAfterPrint: () => setPrintVisible(false),
  });

  const handleOkDelete = () => {
    setDeleteVisible(true);
    setConfirmLoading(true);
    axios.delete(`${API_BASE_URL}patient/${id}`).then((res) => {
      setTimeout(() => {
        setDeleteVisible(false);
        setConfirmLoading(false);
        navigate(-1);
      }, 600);
    });
  };

  const handleOkUpdate = () => {
    setConfirmLoading(true);
  };

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const handleCancel = () => {
    setDeleteVisible(false);
    setUpdateVisible(false);
  };

  const updateFormInternal = async (e) => {
    const { patient } = e;
    const { image, ...all } = patient;

    axios.put(`${API_BASE_URL}patient/${id}`, all).then((res) => {
      setClient({
        ...res.data,
      });
    });

    setConfirmLoading(false);
    setUpdateVisible(false);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleUploadChange = (e) => {
    getBase64(e.file.originFileObj, (url64) => {
      setImageUrl(url64);
    });
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    return isJpgOrPng;
  }

  const editMatter = () => {
    setUpdateVisible(true);
    formeUpdate.setFieldsValue({
      contact: {
        ...client,
        age: parseInt(client.age),
      },
    });
  };

  return (
    <div className="slb2">
      <Tabs
        style={{ marginTop: 20 }}
        defaultActiveKey="1"
        tabBarStyle={{}}
        tabBarExtraContent={{
          left: (
            <>
              <Button onClick={editMatter} type="text">
                <EditOutlined />
              </Button>
              <Button onClick={() => setDeleteVisible(true)} type="text">
                <DeleteOutlined />
              </Button>
              <Button onClick={handlePrint} type="text">
                <PrinterOutlined />
              </Button>
            </>
          ),
        }}
        type="card"
      >
        <TabPane tab={"چارەخواز"} key="1">
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", direction: "rtl", width: "100%" }} ref={componentRef}>
            <Row justify={printVisible ? "center" : "space-around"} style={{ marginTop: 30, width: "100%" }}>
              <Col className="com" style={{ borderRadius: 8, padding: 30, backgroundColor: token.colorBgContainer }} xs={23}>
                <Row justify={"space-between"}>
                  <Col style={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }} xs={20}>
                    <Image
                      width={150}
                      height={150}
                      style={{
                        objectFit: "cover",
                        borderRadius: 80,
                        margin: "0 auto",
                      }}
                      src={client.image ? client.image : Default}
                    />
                    <Space className="flex items-start md:items-center w-full mt-[20px] md:flex-row flex-col">
                      <Title style={{ display: "inline" }} level={3}>
                        ناوی تەواو : {client.fullName}
                      </Title>
                      <Title style={{ margin: 0, color: "#29AAF2", display: "inline", opacity: 0.5 }} level={4}>
                        ID-00 {id}
                      </Title>
                    </Space>
                  </Col>
                  <Col>
                    <QRCode color="#14213d" size={100} bordered={false} value={window.location.href} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Form colon={false} layout="horizontal" labelCol={{ span: 3 }}>
              <Row style={{ marginTop: 40 }} justify="center">
                <Col className="com" style={{ borderRadius: 8, padding: 30, backgroundColor: token.colorBgContainer }} xs={23}>
                  <Title level={5} style={{ display: "block", color: "#222" }}>
                    زانیاری چارەخواز
                  </Title>

                  <Row justify="center">
                    <Col xs={24}>
                      <Form.Item
                        style={{ margin: "8px 0" }}
                        label={
                          <p
                            style={{
                              display: "inline",
                              color: "#29AAF2",
                              fontSize: "16px",
                              margin: "auto 0",
                            }}
                          >
                            ژ.م مۆبایل :{" "}
                          </p>
                        }
                      >
                        <div
                          style={{
                            borderBottom: "1px dashed #333",
                            padding: "2px 0",
                            width: "50%",
                          }}
                          className="underGround2"
                        >
                          <a target="_blank" href={`https://wa.me/${client.phoneNumber}/`}>
                            <Paragraph
                              copyable
                              style={{
                                display: "inline",
                                fontSize: "16px",
                                margin: "auto 0",
                                color: "#1c5fed",
                                textDecoration: "underline",
                              }}
                            >
                              {client.phoneNumber}
                            </Paragraph>
                          </a>
                        </div>
                      </Form.Item>

                      <Form.Item
                        style={{ margin: "8px 0" }}
                        label={
                          <p
                            style={{
                              display: "inline",
                              color: "#29AAF2",
                              fontSize: "16px",
                              margin: "auto 0",
                            }}
                          >
                             وشەی نهێنی :{" "}
                          </p>
                        }
                      >

<div
                          style={{
                            borderBottom: "1px dashed #333",
                            padding: "2px 0",
                            width: "50%",
                          }}
                          className="underGround2"
                        >
                        
                        {/* <div style={{ margin: "10px 0" }}> */}
                <Paragraph style={{ display: "inline", fontSize: "16px",margin:0 }}>{!visablePassword ?(client.password?.charAt(0) +client.password?.charAt(1)+client.password?.charAt(2)+client.password?.charAt(3) +"*******"):client.password}</Paragraph>
                <Button onClick={() => setVisablePassword(!visablePassword)} type="link" style={{ display: "inline", fontSize: "16px",margin:0 }}>{visablePassword ?<EyeOutlined />:<EyeInvisibleOutlined/>}</Button>
              {/* </div> */}
                        </div>

                      </Form.Item>

                      <Form.Item
                        style={{ margin: "8px 0" }}
                        label={
                          <p
                            style={{
                              display: "inline",
                              color: "#29AAF2",
                              fontSize: "16px",
                              margin: "auto 0",
                            }}
                          >
                            تەمەن :{" "}
                          </p>
                        }
                      >
                        <div
                          style={{
                            borderBottom: "1px dashed #333",
                            padding: "2px 0",
                            width: "50%",
                          }}
                          className="underGround2"
                        >
                          <Paragraph
                            copyable
                            style={{
                              display: "inline",
                              fontSize: "16px",
                              margin: "auto 0",
                            }}
                          >
                            {" "}
                            {client.age}
                          </Paragraph>
                        </div>
                      </Form.Item>

                      <Form.Item
                        style={{ margin: "8px 0" }}
                        label={
                          <p
                            style={{
                              display: "inline",
                              color: "#29AAF2",
                              fontSize: "16px",
                              margin: "auto 0",
                            }}
                          >
                            بار :{" "}
                          </p>
                        }
                      >
                        <div
                          style={{
                            borderBottom: "1px dashed #333",
                            padding: "2px 0",
                            width: "50%",
                          }}
                          className="underGround2"
                        >
                          <Tag style={{ fontSize: 17 }} color={client.status === "true" ? "green" : "red"}>
                            {" "}
                            {client.status === "true" ? "چالاک" : "ئەرشیف"}
                          </Tag>
                        </div>
                      </Form.Item>

                      <Form.Item
                        style={{ margin: "8px 0" }}
                        label={
                          <p
                            style={{
                              display: "inline",
                              color: "#29AAF2",
                              fontSize: "16px",
                              margin: "auto 0",
                            }}
                          >
                            بەرواری دروستکردن :{" "}
                          </p>
                        }
                      >
                        <div
                          style={{
                            borderBottom: "1px dashed #333",
                            padding: "2px 0",
                            width: "50%",
                          }}
                          className="underGround2"
                        >
                          <Paragraph
                            copyable
                            style={{
                              display: "inline",
                              fontSize: "16px",
                              margin: "auto 0",
                            }}
                          >
                            {" "}
                            {client.date}
                          </Paragraph>
                        </div>
                      </Form.Item>

               

                   
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>

          <Modal title={translation.Delete + " " + translation.Contact} open={deleteVisible} onOk={handleOkDelete} confirmLoading={confirmLoading} onCancel={handleCancel} centered okText={translation.Delete} cancelText={translation.Cancel}>
            <p>
              {translation.Doyouwantdelete} :{clientSolo.f_name} {clientSolo.l_name}
            </p>
          </Modal>

          <Modal okButtonProps={{ form: "myForm", key: "submit", htmlType: "submit" }} title={translation.Update} open={updateVisible} onOk={handleOkUpdate} centered confirmLoading={confirmLoading} onCancel={handleCancel} okText={translation.Update} cancelText={translation.Cancel}>
            <Form form={formeUpdate} {...layout} name="nest-messages" id="myForm" onFinish={updateFormInternal}>
              <Row justify="center">
                <Form.Item name={["patient", "image"]} style={{ margin: "8px 0" }}>
                  <img
                    src={client.image ? client.image : Default}
                    style={{
                      width: 100,
                      height: 100,
                      objectFit: "cover",
                      borderRadius: 60,
                    }}
                  />
                </Form.Item>
              </Row>

              <Form.Item
                style={{ margin: "8px 0" }}
                name={["patient", "fullName"]}
                label={"ناوی تەواو"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder={"ناوی تەواو"} />
              </Form.Item>

              <Form.Item style={{ margin: "8px 0" }} name={["patient", "phoneNumber"]} label={"ژم.مۆبایل "}>
                <Input placeholder={"ژم.مۆبایل "} />
              </Form.Item>

              <Form.Item style={{ margin: "8px 0" }} name={["patient", "age"]} label={translation.Age}>
                <InputNumber placeholder={translation.Age} />
              </Form.Item>

              <Form.Item style={{ margin: "8px 0" }} name={["patient", "status"]} label={"بار"}>
                <Select>
                  <Select.Option value={true}>چالاک</Select.Option>
                  <Select.Option value={false}>بلاک لیست</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>
        </TabPane>

        {(authSelector.role === "admin" || authSelector.role === "Secretary") && (
          <TabPane tab={"پێشتر و دواتر"} key="6">
            <BeforeAfter />
          </TabPane>
        )}

        <TabPane tab={"ئەنجامی تاقیگە"} key="4">
          <LabResult />
        </TabPane>

        <TabPane tab={<Paragraph style={{ margin: "auto" }}>({count}) سەردانکردن</Paragraph>} key="8">
          <Visit />
        </TabPane>

        {(authSelector.role === "admin" || authSelector.role === "Secretary") && (
          <TabPane tab={"تێبینی"} key="5">
            <NotesPatient />
          </TabPane>
        )}

        {(authSelector.role === "admin" || authSelector.role === "Secretary") && (
          <TabPane tab={"داواکاری"} key="3">
            <Dawakari />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
}
//}

export default PatientDetail;
